import { parseISO } from "date-fns";
import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { abbreviateNumber } from "../../../../../utils";
import "./MembershipTrend.scss";
import Spinner from "../../../../-common/Spinner";
import pluralize from "../../../../../utils/pluralize";

const MembershipTrend = ({ transactions, totalYearsOfMembership = 0 }) => {
  const data = Object.entries(
    (transactions || []).reduce((acc, next) => {
      const year = parseISO(next.date).getFullYear();
      if (acc[year]) acc[year] += next?.amountMetadata?.totalDonationAmount || 0;
      else acc[year] = next?.amountMetadata?.totalDonationAmount || 0;
      return acc;
    }, {})
  )
    .map(([year, total]) => ({
      x: "'" + String(year).slice(-2),
      y: Number((total / 100).toFixed(2))
    }))
    .slice(-20);

  const contents = data?.length ? (
    <ResponsiveContainer >
      <BarChart data={data} margin={{ top: 16, bottom: 16 }}>
        <XAxis dataKey="x" tick={{ fill: 'var(--Gray500)', dy: 10, fontSize: 12 }} />
        <YAxis
          tickFormatter={(t) => `$${abbreviateNumber(t, 0)}`}
          tick={{ fill: 'var(--Gray500)', fontSize: 12 }}
          tickCount={10}
          axisLine={false}
          tickLine={false}
        />
        <CartesianGrid vertical={false} stroke="var(--Gray300)" />
        <Tooltip formatter={(value) => `$${value}`} labelStyle={{ color: 'var(--Gray500)', fontSize: 12 }} />
        <Bar dataKey="y" fill={'var(--primary-color)'} borderRadius={[3, 3, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <div className="spinner-container">
      <Spinner color="var(--primary-color)" />
    </div>
  );

  return (
    <div className="membership-trend">
      <div className="chart-title">Membership By Year (Max 20 {pluralize("year", totalYearsOfMembership)})</div>
      <div className="chart-wrapper" >
        {contents}
      </div>
    </div>
  );
};

export default MembershipTrend;
