import React from 'react';
import Select from '@/common/Select2';

const FormikSelectField = ({
  options,
  field,
  form,
  label,
}) => (
  <Select
    label={label}
    options={options}
    name={field.name}
    value={options?.find(option => option.value === field.value) || ''}
    onChange={option => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
  />
);

export default FormikSelectField;
