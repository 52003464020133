import React, {
  useState, useEffect,
} from 'react';
import commaNumber from 'comma-number';
import {
  FiUser,
  FiExternalLink,
} from 'react-icons/fi';
import { MdPhoneIphone } from 'react-icons/md';
import {
  format,
  parseISO,
} from 'date-fns';

import Select from '@/common/Select2';
import ClubMembersStore from '@/stores/ClubMembersStore';
import UsersStore from '@/stores/UsersStore';
import AuthStore from '@/stores/AuthStore';

import './DonorDetailsBox.scss';

const DonorDetailsBox = ({
  media,
  firstName,
  lastName,
  currentMembershipLevel,
  totalPriorityPoints,
  currentRank,
  totalRank,
  phoneNumbers,
  otherEmails,
  email,
  address,
  DOAssignment,
  DOAssignmentId,
  nextLevelAnnualContribution,
  YTDContributions,
  nextMembershipLevel,
  recentMobileAppUsage,
  membershipExpirationDate,
  userId,
}) => {
  const [
    localDOId,
    setLocalDOId,
  ] = useState(DOAssignmentId);

  useEffect(() => {
    if (DOAssignmentId) setLocalDOId(DOAssignmentId);
  }, [DOAssignmentId]);

  const DOAssignmentOptions = UsersStore?.users?.map(departmentUser => ({
    label: `${departmentUser.profile.firstName} ${departmentUser.profile.lastName}`,
    value: departmentUser.userId,
  }));

  const localDOAssignment = DOAssignmentOptions?.find(o => o?.value === localDOId);

  const updateDO = async ({ value: departmentUserId }) => {
    setLocalDOId(departmentUserId);
    const success = await ClubMembersStore?.setAssignedDOForClubMember(userId, departmentUserId);

    if (!success) setLocalDOId(localDOId);
  };

  const profileImage = media ?
    (
      <img
        alt="The club member"
        src={media}
        className="donor-details-user-profile"
      />
    ) :
    (
      <div className="donor-details-user-profile">
        <FiUser
          size={144}
          color="var(--White)"
          viewBox='0 0.5 24 24'
        />
      </div>
    );

  return (
    <aside className="donor-details-box">
      <div className="donor-details-user">{profileImage}</div>
      <div className="donor-details-name">{`${firstName} ${lastName}`}</div>
      <div className="donor-details-info-row">
        <div className="info-section">
          <div className="title-line">{currentMembershipLevel}</div>
          {!!nextLevelAnnualContribution && !!YTDContributions && !!nextMembershipLevel && (
            <div className="detail-line">
              {`$${nextLevelAnnualContribution - YTDContributions} from ${nextMembershipLevel}`}
            </div>
          )}
        </div>
        <div className="divider" />
        <div className="info-section">
          <div className="title-line">{`${commaNumber(totalPriorityPoints)} Points`}</div>
          <div className="detail-line">
            {!!currentRank && !!totalRank &&
              `Rank: ${commaNumber(currentRank)} of ${commaNumber(totalRank)}`}
          </div>
        </div>
      </div>
      {!!recentMobileAppUsage && (
      <div className="mobile-usage-row">
        <div className="icon-wrapper">
          <MdPhoneIphone
            size={18}
            color="var(--White)"
          />
        </div>
        <div className="details-wrapper">
          <div className="details-title">Mobile App Usage</div>
          <div className="details-text">{`Opened ${format(new Date(recentMobileAppUsage), 'MM/dd/yyyy')}`}</div>
        </div>
        <FiExternalLink
          size={20}
          color="var(--White)"
        />
      </div>
      )}
      <div className="info-row info-row--with-top-border">
        <div className="info-title">Phone:</div>
        {phoneNumbers?.length ?
          (
            phoneNumbers.map(number => (
              <div
                className="info-text"
                key={number}
              >
                {number}
              </div>
            ))
          ) :
          (
            <div className="info-text">-</div>
          )}
      </div>
      <div className="info-row">
        <div className="info-title">Primary Email:</div>
        <div className="info-text">{email}</div>
      </div>
      {!!otherEmails?.length && (
        <div className="info-row">
          <div className="info-title">Other Emails:</div>
          {otherEmails.map(emailAddress => (
            <div
              className="info-text"
              key={emailAddress}
            >
              {emailAddress}
            </div>
          ))}
        </div>
      )}
      {!!address?.addressLine1 && (
        <div className="info-row">
          <div className="info-title">Address:</div>
          <div className="info-text">{`${address.addressLine1} ${address.addressLine2}`}</div>
          <div className="info-text">{`${address.city}, ${address.state} ${address.zip}`}</div>
        </div>
      )}
      {!!membershipExpirationDate && (
        <div className="info-row">
          <div className="info-title">Membership Expiration:</div>
          <div className="info-text">
            {membershipExpirationDate ?
              format(parseISO(membershipExpirationDate), 'MMM d, yyyy') :
              null}
          </div>
        </div>
      )}
      <section className="info-row info-row--with-top-border">
        <div className="info-title">Assigned Development Officer:</div>
        {AuthStore.isAdminUser ?
          (
            <Select
              value={localDOAssignment}
              onChange={updateDO}
              options={DOAssignmentOptions}
            />
          ) :
          (
            <div className="info-text">{DOAssignment}</div>
          )}
      </section>
    </aside>
  );
};

export default DonorDetailsBox;
