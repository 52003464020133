export const mockedMembershipBenefits = [
  {
    membershipId: 1,
    minDonationThreshold: 100,
    maxDonationThreshold: 200,
    name: 'MOCKED Silver Ram',
    benefits: [
      {
        id: 1,
        teamIconName: 'basketball',
        sport: 'Basketball',
        maxTickets: 4,
        section: 'Lower',
        priority: '1st',
        parking: 'None',
        extras: ['Access to Buckeye Club Lounge'],
      },
      {
        id: 2,
        teamIconName: 'baseball',
        sport: 'Baseball',
        maxTickets: 8,
        section: 'VIP',
        priority: '2nd',
        parking: 'A Lot',
        extras: [
          'Pregame meals',
          'Halftime snacks',
          '1 Parking pass',
        ],
      },
    ],
  },
  {
    membershipId: 2,
    minDonationThreshold: 200,
    maxDonationThreshold: 300,
    name: 'MOCKED Champion',
    benefits: [
      {
        id: 3,
        teamIconName: 'basketball',
        sport: 'Basketball',
        maxTickets: 5,
        section: 'Lower',
        priority: '1st',
        parking: 'None',
        extras: ['Access to Buckeye Club Lounge'],
      },
    ],
  },
];

export const mockedMembershipTickets = [
  {
    ticketId: '321',
    sport: 'Basketball',
    section: '34',
    parking: 'Gold Lot',
    seats: [
      '12',
      '13',
      '14',
      '15',
    ],
    maxTickets: 8,
  },
  {
    ticketId: '333',
    sport: 'Baseball',
    section: '4',
    parking: 'Silver Lot',
    seats: [
      '1',
      '2',
      '3',
    ],
    maxTickets: 6,
  },
  {
    ticketId: '3334',
    sport: 'Baseball',
    section: '4',
    parking: 'Silver Lot',
    seats: [
      '1',
      '2',
      '3',
    ],
    maxTickets: 6,
  },
  {
    ticketId: '3333',
    sport: 'Football',
    section: '4',
    parking: 'Silver Lot',
    seats: [
      '1',
      '2',
      '3',
    ],
    maxTickets: 6,
  },
];
