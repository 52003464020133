import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Table } from 'ka-table';
import { DataType } from 'ka-table/enums';
import { FiExternalLink } from 'react-icons/fi';

import Spinner from '@/common/Spinner';
import ContributionDetailsModalContainer from '@/modals/ContributionDetailsModal';
import AmountCell from './AmountCell';

import './ContributionsTable.scss';

const columns = [
  {
    dataType: DataType.Date,
    key: 'date',
    format: d => format(d, 'M/d/yyyy'),
    title: 'Date',
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: 'amountMetadata.totalDonationAmount',
    title: 'Amount',
  },
];

const ContributionsTable = ({
  transactions,
  donorTransactionsCSV,
}) => {
  const [
    isDetailsModalOpen,
    setIsDetailsModalOpen,
  ] = useState(false);

  const openModal = () => {
    setIsDetailsModalOpen(true);
  };

  const closeModal = () => {
    setIsDetailsModalOpen(false);
  };
  const contents = transactions ?
    (
      <Table
        columns={columns}
        data={transactions}
        rowKeyField="transactionId"
      />
    ) :
    (
      <div className="spinner-container">
        <Spinner color="var(--primary-color)" />
      </div>
    );

  return (
    <>
      <div className="contributions-table-container">
        <div className="contributions-table-title">
          <div className="title-text">Contribution History</div>
          <button
            type="button"
            className="open-modal-button"
            onClick={openModal}
          >
            <FiExternalLink
              size={18}
              color="var(--Gray500)"
            />
          </button>
        </div>
        {contents}
      </div>
      <ContributionDetailsModalContainer
        isOpen={isDetailsModalOpen}
        close={closeModal}
        transactions={transactions}
        donorTransactionsCSV={donorTransactionsCSV}
      />
    </>
  );
};

export default observer(ContributionsTable);
