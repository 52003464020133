import { observable, computed, action, autorun } from 'mobx';
import isEqual from 'lodash.isequal';
import { request } from '@/utils';
import { sortValues } from '@/constants/sortValues';
import uploadFile from '../services/FileUploadService';
import AuthStore from './AuthStore';
import CampaignsStore from './CampaignsStore';
import AppStateStore from './AppStateStore';
import TeamsStore from './TeamsStore';
import { toast } from 'react-toastify';

const searchBulletins = searchText => r => {
  const search = searchText.toLowerCase();
  const matchesTitle = r.subject.toLowerCase().includes(search);
  const message = r.clubMessage || r.facebookMessage || r.twitterMessage;
  const matchesDescription = message?.toLowerCase().includes(search);

  return matchesTitle || matchesDescription;
};

export const filterBulletinsOptions = [{ value: 'All', label: 'All' }];

const filterBulletins = filter => r => {
  if (filter.value === 'All') return true;
  return r.category === filter.value;
};

export const sortBulletinsOptions = [
  { label: sortValues.ALPH_ASC, value: sortValues.ALPH_ASC },
  { label: sortValues.ALPH_DESC, value: sortValues.ALPH_DESC },
  { label: sortValues.NEWEST, value: sortValues.NEWEST },
  { label: sortValues.OLDEST, value: sortValues.OLDEST },
];

const sortBulletins = sort => (b1, b2) => {
  const title1 = b1.subject.toUpperCase();
  const title2 = b2.subject.toUpperCase();
  if (sort.value === sortValues.ALPH_ASC) {
    return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
  } else if (sort.value === sortValues.ALPH_DESC) {
    return title1 > title2 ? -1 : title1 < title2 ? 1 : 0;
  } else if (sort.value === sortValues.NEWEST) {
    return new Date(b2.createdDate).valueOf() - new Date(b1.createdDate).valueOf();
  } else if (sort.value === sortValues.OLDEST) {
    return new Date(b2.createdDate).valueOf() - new Date(b1.createdDate).valueOf();
  }
};

class PlansStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        // this.fetchPlans();
      } else {
        this.clear();
      }
    });
  }

  @action async fetchPlans() {
    try {
      const plans = await request.get('/v1/plans');
      this.rawPlans = plans.map(p => ({
        ...p,
        createdDate: new Date(p.createdDate),
      }));
      return plans;
    } catch (err) {
      console.warn(err);
    }
  }

  // PLANS
  @observable rawPlans = [];

  @computed get plans() {
    return (this.rawPlans || []).filter(Boolean);
    // .filter(c => c.first_name.includes(this.search))
    // .map(c => ({
    //   ...c
    //   //,tiers: this.tiers[c.campaignId] || []
    // }))
  }

  // SELECTED PLAN
  @observable selectedPlanId;

  @action setSelectedPlan(planId) {
    this.selectedPlanId = planId;
  }

  @computed get selectedPlan() {
    let planMatch = this.plans.find(p => p.planId === this.selectedPlanId) || {};
    let { campaignId, ...matchingPlan } = planMatch;
    const campaignMatch = CampaignsStore.campaigns.find(c => c.campaignId === campaignId);
    let { teamId } = campaignMatch || {};
    const teamMatch = TeamsStore.teams.find(t => t.teamId === teamId);
    matchingPlan.campaign = campaignMatch;
    matchingPlan.team = teamMatch;
    return matchingPlan;
  }

  //ORCHESTRATION METHODS
  @action async savePlan(localPlan, originalPlan, saveMode, campaignId) {
    try {
      AppStateStore.setLoading(true);
      const {
        steps,
        clubMedia,
        clubMediaFile,
        facebookMedia,
        facebookMediaFile,
        twitterMedia,
        twitterMediaFile,
        instagramMedia,
        instagramMediaFile,
        ...planObject
      } = localPlan;

      const mediaFiles = [
        ...(clubMediaFile ? [{ category: 'club', file: clubMediaFile }] : []),
        ...(facebookMediaFile ? [{ category: 'facebook', file: facebookMediaFile }] : []),
        ...(twitterMediaFile ? [{ category: 'twitter', file: twitterMediaFile }] : []),
        ...(instagramMediaFile ? [{ category: 'Instagram', file: instagramMediaFile }] : []),
      ];

      if (saveMode === 'create') {
        const { planId } = await this.addPlan({
          ...planObject,
          campaignId,
        });

        if (mediaFiles.length) {
          await this.multipleFilesUpload(mediaFiles, planId);
        }

        // IS THIS NEEDED?
        const stepsToBeAdded = this.getPlanStepDifferences(localPlan?.steps, originalPlan?.steps);

        const stepsToBeAddedPromises = stepsToBeAdded?.map(step => {
          const newPlanStepObject = { ...step, planId };
          return this.addStepToPlan(newPlanStepObject);
        });

        await Promise.all(stepsToBeAddedPromises);
      } else if (saveMode === 'edit') {
        const editMadeToPlan = this.comparePlanValues(localPlan, originalPlan);
        let editPlanPromise;
        if (editMadeToPlan) {
          editPlanPromise = this.editPlan(planObject);
        }

        const { planId } = localPlan;

        const stepsToBeAdded = this.getPlanStepDifferences(localPlan?.steps, originalPlan?.steps);
        const stepsToBeAddedPromises = stepsToBeAdded?.map(step => {
          const newPlanStepObject = { ...step, planId };
          return this.addStepToPlan(newPlanStepObject);
        });

        const stepsToUpdate = localPlan?.steps
          ?.filter(this.compareStepValues(originalPlan?.steps))
          ?.filter(s => !stepsToBeAdded.map(stba => stba.planStepId).includes(s.planStepId));
        const stepsToBeUpdatedPromises = stepsToUpdate?.map(step => {
          const editedPlanStepObject = { ...step, planId };
          return this.editPlanStep(editedPlanStepObject);
        });

        const stepsToBeDeleted = this.getPlanStepDifferences(originalPlan?.steps, localPlan?.steps);
        const stepsToBeDeletedPromises = stepsToBeDeleted?.map(step => {
          const planStepObject = { planId, ...step };
          return this.deletePlanStep(planStepObject);
        });

        await Promise.all(
          [editPlanPromise]
            .concat(stepsToBeAddedPromises)
            .concat(stepsToBeUpdatedPromises)
            .concat(stepsToBeDeletedPromises),
        );

        if (mediaFiles.length) {
          await this.multipleFilesUpload(mediaFiles, planId);
        }
      }
      AppStateStore.setLoading(false);
    } catch (e) {
      console.warn('an error: ', e);
      AppStateStore.setLoading(false);
    }
  }

  @action async multipleFilesUpload(mediaFiles, planId) {
    const filePromises = mediaFiles.map(({ file, category }) => {
      return this.uploadFile(file, category);
    });

    const finishedFileUploads = await Promise.all(filePromises);
    let planUpdateObject = {};
    finishedFileUploads.forEach(fileURL => {
      fileURL = fileURL.split('?')[0];
      if (fileURL.includes('club')) planUpdateObject.clubMedia = fileURL;
      if (fileURL.includes('facebook')) planUpdateObject.facebookMedia = fileURL;
      if (fileURL.includes('twitter')) planUpdateObject.twitterMedia = fileURL;
      if (fileURL.includes('instagram')) planUpdateObject.instagramMedia = fileURL;
    });

    await this.editPlan({
      planId,
      ...planUpdateObject,
    });
  }

  // PLAN CRUD ACTIONS
  @action async addPlan(planObject) {
    try {
      let newPlan;
      AppStateStore.setLoading(true);
      newPlan = await request.post('/v1/plans', {
        body: planObject,
      });

      this.addPlanInPlace(newPlan);
      AppStateStore.setLoading(false);
      toast('New plan added');
      return newPlan;
    } catch (err) {
      AppStateStore.setLoading(false);
      console.warn(err);
    }
  }

  @action async editPlan(planObject) {
    const { planId } = planObject;
    try {
      AppStateStore.setLoading(true);
      const editedPlan = await request.put(`/v1/plans/${planId}`, {
        body: planObject,
      });

      this.updatePlanInPlace(editedPlan);
      AppStateStore.setLoading(false);
      toast('Plan has been edited');
    } catch (err) {
      AppStateStore.setLoading(false);
      console.warn(err);
    }
  }

  @action async deletePlan(planId) {
    const isBulletin = this?.plans?.find(p => p.planId === planId)?.type === 'bulletin';

    try {
      AppStateStore.setLoading(true);
      await request.delete(`/v1/plans/${planId}`);
      this.deletePlanInPlace(planId);
      AppStateStore.setLoading(false);
      toast(`${isBulletin ? 'Bulletin' : 'Plan'} deleted.`);
      return true;
    } catch (err) {
      AppStateStore.setLoading(false);
      console.warn(err);
      toast(`Error deleting ${isBulletin ? 'bulletin' : 'plan'}.`);
      return false;
    }
  }

  @action addPlanInPlace(newPlan) {
    this.rawPlans = this.rawPlans.concat(newPlan);
  }

  @action updatePlanInPlace(updatedPlan) {
    this.rawPlans = this.rawPlans.map(p => {
      if (p.planId === updatedPlan.planId) {
        return updatedPlan;
      }
      return p;
    });
  }

  @action deletePlanInPlace(planId) {
    this.rawPlans = this.rawPlans.filter(p => p.planId !== planId);
  }

  // PLAN STEPS CRUD ACTIONS

  @action async addStepToPlan(planStepObject) {
    const { levels, teamIds } = planStepObject;
    const planId = planStepObject?.planId || this.activePlanId;
    const updatedPlan = await request.post(`/v1/plans/${planId}/steps`, {
      body: {
        ...planStepObject,
        levels: levels ? levels.join('|*|') : null,
        teamIds: teamIds ? teamIds.join('|*|') : null,
      },
    });
    this.updatePlanInPlace(updatedPlan);
    toast('New step added to plan');
    return updatedPlan;
  }

  @action async editPlanStep(planStepObject) {
    const { planStepId, planId, levels, teamIds, ...updatedObject } = planStepObject;
    const updatedPlan = await request.put(`/v1/plans/${planId}/steps/${planStepId}`, {
      body: {
        ...updatedObject,
        levels: levels ? levels.join('|*|') : null,
        teamIds: teamIds ? teamIds.join('|*|') : null,
      },
    });

    this.updatePlanInPlace(updatedPlan);
    toast('Plan step has been edited');
    return updatedPlan;
  }

  @action async deletePlanStep(planStepObject) {
    const { planStepId, planId } = planStepObject;
    const updatedPlan = await request.delete(`/v1/plans/${planId}/steps/${planStepId}`);
    this.deletePlanStepInPlace({ planStepId, planId });
    toast('Plan step has been deleted');
    return updatedPlan;
  }

  @action deletePlanStepInPlace({ planStepId, planId }) {
    this.rawPlans = this.rawPlans.map(p => {
      if (p.planId === planId) {
        return {
          ...p,
          steps: p.steps.filter(plan => plan.planStepId !== planStepId),
        };
      }
      return p;
    });
  }

  // HELPER FUNCTIONS
  @action async uploadFile(file, category) {
    try {
      const url = await uploadFile(file, category);
      return url;
    } catch (err) {
      console.warn(err);
    }
  }

  getPlanStepDifferences = (baseArray, comparisonArray) => {
    const comparisonArrayIds = comparisonArray.map(ca => ca.planStepId);
    return baseArray.filter(b => !comparisonArrayIds.includes(b.planStepId));
  };

  compareStepValues = originalSteps => step => {
    const planStepContext = originalSteps?.filter(t => t.planStepId === step?.planStepId);
    const { executionDate, platform, status, levels, teamIds } = planStepContext[0] || {};

    if (step?.executionDate !== executionDate) return true;
    if (step?.platform !== platform) return true;
    if (step?.status !== status) return true;
    if (!isEqual(step?.levels, levels)) return true;
    if (!isEqual(step?.teamIds, teamIds)) return true;
    return false;
  };

  comparePlanValues(localPlan, originalPlan) {
    const { steps, ...lPlan } = localPlan;
    const { steps: oSteps, ...oPlan } = originalPlan;

    return Object.keys(lPlan).some(key => lPlan[key] !== oPlan[key]);
  }

  // BULLETINS
  @computed get bulletins() {
    return this.plans
      .filter(p => p.type === 'bulletin')
      .filter(filterBulletins(this.bulletinFilter))
      .filter(searchBulletins(this.bulletinSearch))
      .sort(sortBulletins(this.bulletinSort));
  }

  @computed get pendingBulletins() {
    return this.bulletins.filter(b => b.steps.length === 0);
  }
  // MODAL
  @observable showNewPlanModal = false;

  @action openNewPlanModal = () => (this.showNewPlanModal = true);

  @action closeNewPlanModal = () => (this.showNewPlanModal = false);

  // SEARCH TEAMS
  @observable search = '';

  @computed get futureBulletins() {
    return this.bulletins.filter(b => b.steps.length !== 0 && b.createdDate >= new Date());
  }

  @computed get pastBulletins() {
    return this.bulletins.filter(b => b.steps.length !== 0 && b.createdDate < new Date());
  }

  @observable bulletinSearch = '';

  @action setBulletinSearch = search => (this.bulletinSearch = search);

  @observable bulletinFilter = filterBulletinsOptions[0];

  @action setBulletinFilter(filter) {
    this.bulletinFilter = filter;
  }

  @observable bulletinSort = sortBulletinsOptions[0];

  @action setBulletinSort(sort) {
    this.bulletinSort = sort;
  }

  // CLEANUP
  @action clear() {
    this.rawTeams = [];
  }
}

export default new PlansStore();
