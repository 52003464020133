import React from 'react';
import {
  FaBolt,
  FaBullhorn,
  FaUsers,
  FaStar,
} from 'react-icons/fa';
import commaNumber from 'comma-number';

import { abbreviateNumber } from '@/utils';

import './SummaryNumbers.scss';

const SummaryNumber = ({
  icon: Icon, value, title, indicator,
}) => {
  const indicatorComponent = indicator ?
    (
      <div className="indicator">{indicator}</div>
    ) :
    (
      <div className="indicator empty">&nbsp;</div>
    );

  return (
    <div className="summary-number">
      <div className="icon-wrapper">
        <Icon
          className="summary-icon"
          size={24}
        />
      </div>
      <div className="value">{value}</div>
      <div className="title">{title}</div>
      {indicatorComponent}
    </div>
  );
};

const SummaryNumbers = ({
  YTDContributions,
  lifetimeContributions,
  totalYearsOfMembership,
  currentRank,
  totalRank,
  totalPriorityPoints,
}) => {
  const rankStatement = `All time rank: ${commaNumber(currentRank)} of ${commaNumber(totalRank)}`;
  const summaryNumbers = [
    {
      icon: FaBolt,
      title: 'Priority Points',
      value: commaNumber(totalPriorityPoints) || '-',
    },
    {
      icon: FaBullhorn,
      title: 'YTD Contributions',
      value: !YTDContributions ?
        '-' :
        `$${commaNumber(abbreviateNumber(YTDContributions / 100))}`,
    },
    {
      icon: FaUsers,
      title: 'Lifetime Contributions',
      value: !lifetimeContributions ?
        '-' :
        `$${commaNumber(abbreviateNumber(lifetimeContributions / 100))}`,
      indicator:
        Number.isNaN(lifetimeContributions) || !currentRank || !totalRank ? null : rankStatement,
    },
    {
      icon: FaStar,
      title: `Year${totalYearsOfMembership > 1 ? 's' : ''} of Membership`,
      value: !totalYearsOfMembership ? '-' : totalYearsOfMembership,
    },
  ];

  return (
    <div className="summary-numbers">
      {summaryNumbers.map(stat => (
        <SummaryNumber
          icon={stat.icon}
          title={stat.title}
          value={stat.value}
          indicator={stat.indicator}
          key={stat.title}
        />
      ))}
    </div>
  );
};

export default SummaryNumbers;
