import React from 'react';
import { observer } from 'mobx-react';
import {
  Formik,
  Form,
  Field,
  FastField,
  FieldArray,
} from 'formik';

import ExtendedAddressesStore from '@/stores/ExtendedAddressesStore';
import InstanceConfigStore from '@/stores/InstanceConfigStore';
import FormikAuthInput from '@/common/AuthInput/FormikAuthInput';
import { GarbageIcon } from '@/common/Icons';
import Spinner from '@/common/Spinner';
import Label from '@/common/Label';
import { STATES } from '@/constants/main';
import FormikSelectField from '@/common/FormikSelectField';

import contactDetailsSchema from './contactDetailsSchema';

import './ContactDetails.scss';
import AuthSubmitButton from '../../@Auth/AuthSubmitButton';

const stateOptions = STATES.map(state => ({
  label: state,
  value: state,
}));
const maxContactInformationRows = 3;

const ContactDetails = () => {
  const { addresses } = ExtendedAddressesStore;
  const { tenant } = InstanceConfigStore;

  const onFormSubmit = async values => {
    const emailsMappedObject = values.emails.reduce((acc, current, index) => ({
      ...acc,
      [`email${index + 1}`]: current,
    }), {});

    const phoneNumbersMappedObject = values.phoneNumbers.reduce((acc, current, index) => ({
      ...acc,
      [`phone${index + 1}`]: current,
    }), {});

    const body = {
      ...emailsMappedObject,
      ...phoneNumbersMappedObject,
      streetLine1: values.streetLine1,
      streetLine2: values.streetLine2,
      zip: values.zip,
      city: values.city,
      state: values.state,
      primaryPhoneNumber: values.primaryPhoneNumber,
      primaryEmail: values.primaryEmail,
    };

    await ExtendedAddressesStore.updateExtendedAddress(body);
  };

  return (
    <div className="details-container">
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          emails: [
            addresses?.email1,
            addresses?.email2,
            addresses?.email3,
          ].filter(el => !!el),
          phoneNumbers: [
            addresses?.phone1,
            addresses?.phone2,
            addresses?.phone3,
          ].filter(el => !!el),
          streetLine1: addresses?.streetLine1,
          streetLine2: addresses?.streetLine2,
          zip: addresses?.zip,
          city: addresses?.city,
          state: addresses?.state,
          primaryPhoneNumber: addresses?.primaryPhoneNumber,
          primaryEmail: addresses?.primaryEmail,
        }}
        validationSchema={contactDetailsSchema}
        enableReinitialize
      >
        {({ values }) => (
          <Form>
            <div className="field-group">
              <div className="inputs-container">
                <Label value="Contact Email Addresses" />
                <FieldArray
                  name="emails"
                  render={arrayHelpers => (
                    <div>
                      {values.emails.map((number, index) => (
                        <div
                          key={`email.${index}`}
                          className="array-field"
                        >
                          <Field
                            component={FormikAuthInput}
                            name={`emails.${index}`}
                            placeholder="example@example.com"
                            theme="alt"
                          />
                          {values.emails.length > 1 && (
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              className="remove-button"
                            >
                              <GarbageIcon />
                            </button>
                          )}
                        </div>
                      ))}
                      {values.emails.length < maxContactInformationRows && (
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push('')}
                          className="insert-button"
                        >
                          + Add another email address
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="inputs-container">
                <Field
                  component={FormikSelectField}
                  label="Default Email Address"
                  name="primaryEmail"
                  options={values.emails
                    .filter(el => !!el)
                    .map((el, ind) => ({
                      label: el,
                      value: `email${ind + 1}`,
                    }))}
                />
              </div>
            </div>
            <div className="field-group">
              <div className="inputs-container">
                <Label value="Contact Phone Numbers" />
                <FieldArray
                  name="phoneNumbers"
                  render={arrayHelpers => (
                    <div>
                      {values.phoneNumbers.map((number, index) => (
                        <div
                          key={`phoneNumber.${index}`}
                          className="array-field"
                        >
                          <Field
                            component={FormikAuthInput}
                            name={`phoneNumbers.${index}`}
                            placeholder="(XXX) XXX-XXXX"
                            theme="alt"
                          />
                          {values.phoneNumbers.length > 1 && (
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                              className="remove-button"
                            >
                              <GarbageIcon />
                            </button>
                          )}
                        </div>
                      ))}
                      {values.phoneNumbers.length < maxContactInformationRows && (
                        <button
                          type="button"
                          onClick={() => arrayHelpers.push('')}
                          className="insert-button"
                        >
                          + Add another phone number
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="inputs-container">
                <Field
                  component={FormikSelectField}
                  label="Default Phone Number"
                  name="primaryPhoneNumber"
                  options={values.phoneNumbers
                    .filter(el => !!el)
                    .map((el, ind) => ({
                      label: el,
                      value: `phone${ind + 1}`,
                    }))}
                />
              </div>
            </div>
            <section className="shipping-address">
              <div className="inputs-container">
                <Label value="Address (line 1)" />
                <FastField
                  component={FormikAuthInput}
                  name="streetLine1"
                  placeholder="1234 Somewhere Ln."
                  theme="alt"
                />
              </div>
              <div className="inputs-container">
                <Label value="Address (line 2)" />
                <FastField
                  component={FormikAuthInput}
                  name="streetLine2"
                  placeholder="Unit #1"
                  theme="alt"
                />
              </div>
              <div className="inputs-container inputs-container--wide">
                <div className="inputs-shared-row">
                  <div>
                    <Label value="City" />
                    <FastField
                      component={FormikAuthInput}
                      name="city"
                      placeholder="Anytown"
                      theme="alt"
                    />
                  </div>
                  <Field
                    component={FormikSelectField}
                    label="State"
                    name="state"
                    options={stateOptions}
                  />
                  <div>
                    <Label value="Zip Code" />
                    <FastField
                      component={FormikAuthInput}
                      name="zip"
                      placeholder="XXXXX"
                      theme="alt"
                    />
                  </div>
                </div>
              </div>
              <AuthSubmitButton
                loading={ExtendedAddressesStore.loading}
                btnStyles={{
                  backgroundColor: tenant?.theme.dxmPrimaryColor,
                  marginTop: 0,
                  width: 200,
                }}
              >
                {ExtendedAddressesStore.loading ? <Spinner /> : 'SAVE'}
              </AuthSubmitButton>
            </section>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default observer(ContactDetails);
