export const sortValues = {
  ALPH_ASC: 'A to Z',
  ALPH_DESC: 'Z to A',
  MOST_ACHIEVED: 'Most Achieved',
  LEAST_ACHIEVED: 'Least Achieved',
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  ENDING_SOON: 'Ending Soon',
  LOWEST_THRESHOLD: 'Lowest Threshold',
  HIGHEST_THRESHOLD: 'Highest Threshold',
  MOST_AVAILABLE: 'Most Available',
  AMOUNT_HIGH_TO_LOW: 'Amount High to Low',
  AMOUNT_LOW_TO_HIGH: 'Amount Low to High',
  NAME_A_TO_Z: 'Name A to Z',
  NAME_Z_TO_A: 'Name Z to A',
};
