import { observable, computed, action, autorun } from 'mobx';
import commaNumber from 'comma-number';
import { request, abbreviateNumber } from '../utils';
import AuthStore from './AuthStore';
import { toast } from 'react-toastify';

class StatisticsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchCampaignStats();
      } else {
        this.clear();
      }
    });
  }

  @action async fetchCampaignStats() {
    await Promise.all([
      this.fetchCampaignCount(),
      this.fetchCampaignReach(),
      this.fetchCampaignDonors(),
      this.fetchTotalDonationMetrics(),
      this.fetchTotalDonationsByDay(),
      this.fetchRevenueTrend(),
      this.fetchLatestDonations(),
      this.fetchCampaignHealth(),
    ]);
  }

  @observable totalCampaigns;
  @action async fetchCampaignCount() {
    const { totalCampaigns } = await request.get('/v1/statistics/campaigns/count');
    this.totalCampaigns = totalCampaigns;
    return totalCampaigns;
  }

  @observable totalCampaignReach;
  @action async fetchCampaignReach() {
    const { totalCampaignReach } = await request.get('/v1/statistics/campaigns/reach');
    this.totalCampaignReach = totalCampaignReach;
    return totalCampaignReach;
  }

  @observable totalCampaignDonors;
  @observable appCampaignDonors;
  @observable newCampaignDonors;
  @action async fetchCampaignDonors() {
    const { totalCampaignDonors, appCampaignDonors, newCampaignDonors } = await request.get(
      '/v1/statistics/campaigns/donors',
    );
    this.totalCampaignDonors = totalCampaignDonors;
    this.appCampaignDonors = appCampaignDonors;
    this.newCampaignDonors = newCampaignDonors;
    return totalCampaignDonors;
  }

  @computed get overallDonationRate() {
    if (!this.totalCampaignReach && !this.totalCampaignDonors) return '-';
    else if (this.totalCampaignReach && !this.totalCampaignDonors) return 0;
    else {
      const decimalRate = (this.totalCampaignDonors || 0) / this.totalCampaignReach;
      const totalDecimals = decimalRate > 10 ? 0 : decimalRate > 1 ? 1 : 2;
      const formattedRate = (Math.round(decimalRate * 10000) / 100).toFixed(totalDecimals);
      return formattedRate + '%';
    }
  }

  @observable totalDonated;
  @observable totalDonationGoal;
  @action async fetchTotalDonationMetrics() {
    const { totalDonated, totalDonationGoal } = await request.get(
      '/v1/statistics/campaigns/totaldonations',
    );
    this.totalDonated = Number(totalDonated / 100)?.toFixed(2);
    this.totalDonationGoal = Number(totalDonationGoal / 100)?.toFixed(2);
  }

  @computed get totalPercentOfGoal() {
    if (!this.totalDonated && !this.totalDonationGoal) return '-';
    else if (this.totalDonated && !this.totalDonationGoal) return 0;
    else {
      const decimalRate = (this.totalDonated || 0) / this.totalDonationGoal;
      const totalDecimals = decimalRate > 10 ? 0 : decimalRate > 1 ? 1 : 2;
      const formattedRate = (Math.round(decimalRate * 10000) / 100).toFixed(totalDecimals);
      return formattedRate + '%';
    }
  }

  @computed get avgDonationAmount() {
    if (!this.totalDonated && !this.totalCampaignDonors) return '-';
    else if (this.totalDonated && !this.totalCampaignDonors) return '$0';
    else {
      const decimalAvg = (this.totalDonated || 0) / this.totalCampaignDonors;
      if (Math.abs(decimalAvg) >= 100) {
        return `$${commaNumber(abbreviateNumber(decimalAvg.toFixed(0), 0))}`;
      } else {
        return `$${commaNumber(abbreviateNumber(decimalAvg.toFixed(0), 0))}`;
      }
    }
  }

  @observable totalDonationsByDay = [];
  @action async fetchTotalDonationsByDay() {
    const totalDonationsByCampaignByDay = await request.get(
      '/v1/statistics/campaigns/donationtrend',
    );
    const totalDonationsByDay = Object.values(totalDonationsByCampaignByDay).map(trend => ({
      legendEntry: trend[0].campaignTitle,
      data: trend.map(e => ({ x: e.dayOfWeek, y: e.totalDonations })),
    }));
    this.totalDonationsByDay = totalDonationsByDay;
    return totalDonationsByDay;
  }

  @observable rawRevenueTrend = [];
  @computed get revenueTrend() {
    if (
      this.rawRevenueTrend.reduce(
        (acc, next) => acc + next?.amountMetadata?.totalDonationAmount,
        0,
      ) <= 0
    )
      return [];
    return this.rawRevenueTrend.map(rt => ({
      x: rt.month,
      y: rt.totalDonationAmount / 100,
    }));
  }
  @action async fetchRevenueTrend() {
    const revenueTrend = await request.get('/v1/statistics/campaigns/revenuetrend');
    this.rawRevenueTrend = revenueTrend;
  }

  @observable latestDonations = [];
  @action async fetchLatestDonations() {
    const latestDonations = await request.get('/v1/statistics/campaigns/latestdonations');
    this.latestDonations = latestDonations.map(row => ({
      ...row,
      totalDonationAmount: row.totalDonationAmount
        ? (Number(row.totalDonationAmount) / 100)?.toFixed(2)
        : null,
    }));
  }

  @observable campaignHealth = [];
  @action async fetchCampaignHealth() {
    const campaignHealth = await request.get('/v1/statistics/campaigns/campaignhealth');

    this.campaignHealth = campaignHealth.map(row => ({
      ...row,
      totalDonated: row.totalDonated ? (Number(row.totalDonated) / 100)?.toFixed(2) : null,
    }));
  }

  @computed get liveCampaigns() {
    return this.campaignHealth?.filter(r => r.status === 'Live')?.length || 0;
  }

  @observable rawCampaignSpecificStats = {};
  @computed get campaignSpecificStats() {
    const {
      donors: { totalCampaignReach, totalCampaignDonors, newCampaignDonors } = {},
      donations: { totalDonated, totalDonationGoal } = {},
      revenueTrend,
      health: campaignHealth,
      latestDonations,
    } = this.rawCampaignSpecificStats || {};

    let overallDonationRate;
    if (!totalCampaignReach && !totalCampaignDonors) overallDonationRate = '-';
    else if (totalCampaignReach && !totalCampaignDonors) overallDonationRate = 0;
    else {
      const decimalRate = (totalCampaignDonors || 0) / totalCampaignReach;
      const totalDecimals = decimalRate > 10 ? 0 : decimalRate > 1 ? 1 : 2;
      const formattedRate = (Math.round(decimalRate * 10000) / 100).toFixed(totalDecimals);
      overallDonationRate = formattedRate + '%';
    }

    let totalPercentOfGoal;
    if (!totalDonated && !totalDonationGoal) totalPercentOfGoal = '-';
    else if (totalDonated && !totalDonationGoal) totalPercentOfGoal = 0;
    else {
      const decimalRate = (totalDonated || 0) / totalDonationGoal;
      const totalDecimals = decimalRate > 10 ? 0 : decimalRate > 1 ? 1 : 2;
      const formattedRate = (Math.round(decimalRate * 10000) / 100).toFixed(totalDecimals);
      totalPercentOfGoal = formattedRate + '%';
    }

    let avgDonationAmount;
    if (!totalDonated && !totalCampaignDonors) avgDonationAmount = '-';
    else if (totalDonated && !totalCampaignDonors) avgDonationAmount = '$0';
    else {
      const decimalAvg = (totalDonated / 100 || 0) / totalCampaignDonors;
      if (Math.abs(decimalAvg) >= 100) {
        avgDonationAmount = `$${commaNumber(abbreviateNumber(decimalAvg.toFixed(0), 0))}`;
      } else {
        avgDonationAmount = `$${commaNumber(abbreviateNumber(decimalAvg.toFixed(0), 0))}`;
      }
    }

    return {
      ...this.rawCampaignSpecificStats,

      totalCampaignReach,
      totalCampaignDonors,
      newCampaignDonors,
      totalDonated: Number(totalDonated / 100)?.toFixed(2),
      totalDonationGoal: Number(totalDonationGoal / 100)?.toFixed(2),

      revenueTrend: revenueTrend?.map(rt => ({
        x: rt.month,
        y: rt.totalDonationAmount / 100,
      })),

      campaignHealth: campaignHealth?.map(ch => ({
        ...ch,
        totalDonated: ch?.totalDonated / 100 || 0,
      })),

      latestDonations: latestDonations?.map(ch => ({
        ...ch,
        totalDonationAmount: isNaN(Number(ch?.totalDonationAmount) / 100)
          ? 0
          : Number(ch?.totalDonationAmount) / 100,
      })),

      overallDonationRate,
      totalPercentOfGoal,
      avgDonationAmount,

      liveCampaigns: campaignHealth?.[0]?.status === 'Live' ? 1 : 0,
    };
  }

  @action async fetchCampaignSpecificStats(campaignId) {
    try {
      const specificStats = await request.get(`/v1/statistics/campaigns/${campaignId}`);
      this.rawCampaignSpecificStats = specificStats;
      return true;
    } catch {
      toast('Error filtering statistics.');
      return false;
    }
  }

  // CLEANUP
  @action clear() {
    this.totalCampaigns = null;
    this.totalCampaignReach = null;
    this.totalCampaignDonors = null;
    this.appCampaignDonors = null;
    this.newCampaignDonors = null;
    this.totalDonated = null;
    this.totalDonationGoal = null;
    this.totalDonationsByDay = [];
    this.rawRevenueTrend = [];
    this.latestDonations = [];
    this.campaignHealth = [];
  }
}

export default new StatisticsStore();
