import {
  observable, action, autorun, computed,
} from 'mobx';
import { toast } from 'react-toastify';
import { request } from '@/utils';
import AuthStore from './AuthStore';
import ProfileStore from './ProfileStore';

class DonorMessagesStore {
  constructor() {
    autorun(() => {
      if (AuthStore.DonorAPIReady) {
        this.fetchMessages();
      } else {
        this.clear();
      }
    });
  }

  @action fetchMessages = async () => {
    try {
      const messages = await request.dwpAuthenticated.get(
        '/v1/messages',
        { headers: { ...(AuthStore.userId ? { 'X-Nickel-User-Id': AuthStore.userId } : {}) } }
      );

      this.rawMessages = messages;
      this.loadingMessages = false;
    } catch (err) {
      console.warn(err);
      this.loadingMessages = false;
    }
  };

  @observable loadingMessages = false;

  @observable rawMessages = [];

  @computed get messages() {
    return this.rawMessages
      .map(m => ({
        ...m,
        createdDate: new Date(m.createdDate),
      }))
      .sort((a, b) => a.createdDate - b.createdDate)
      .map((m, i) => ({
        ...m,
        last: i === this.rawMessages.length - 1,
      }));
  }

  @action sendMessage = async message => {
    const optimisticMessageId = `optimistic-${Math.random()}`;

    try {
      const optimisticMessage = {
        archived: false,
        fromDonor: true,
        message,
        messageId: optimisticMessageId,
        userId: AuthStore.userId,
        createdDate: new Date(),
      };

      this.rawMessages = this.rawMessages?.concat(optimisticMessage);

      const newMessage = await request.dwpAuthenticated.post(
        '/v1/messages',
        {
          body: {
            message,
            fromDonor: true,
            officerId: ProfileStore.rawProfile?.assignedDevelopmentOfficer?.userId,
          },
          headers: { ...(AuthStore.userId ? { 'X-Nickel-User-Id': AuthStore.userId } : {}) },
        }
      );

      this.rawMessages = this.rawMessages
        ?.filter(m => m?.messageId !== optimisticMessageId)
        ?.concat(newMessage);
    } catch (err) {
      this.rawMessages = this.rawMessages?.filter(m => m?.messageId !== optimisticMessageId);
      toast.error(err);
    }
  };

  @action markMessagesRead = async () => {
    try {
      await request.dwpAuthenticated.post(
        '/v1/messages/read',
        {
          body: { officerId: ProfileStore.rawProfile?.assignedDevelopmentOfficer?.userId },
          headers: { ...(AuthStore.userId ? { 'X-Nickel-User-Id': AuthStore.userId } : {}) },
        }
      );

      this.rawMessages = this.rawMessages.map(m => ({
        ...m,
        receipt: new Date(),
      }));
    } catch (err) {
      console.warn(err);
    }
  };

  @action handleIncomingMessage = message => {
    if (!this.rawMessages?.map(m => m?.messageId)?.includes(message?.messageId)) {
      this.rawMessages = this.rawMessages.concat(message);
    }
  };

  @action clear() {
    this.rawMessages = [];
  }
}

export default new DonorMessagesStore();
