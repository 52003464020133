import React from 'react';
import { observer } from 'mobx-react';

import MessagesStore from '@/stores/MessagesStore';
import MessagesChat from '@/common/MessagesChat';

const Messages = ({ clubMember }) => {
  const markMessagesAsRead = () => MessagesStore.markMessagesReadForMemberThread(clubMember.userId);
  const sendMessage = message => MessagesStore.createMessage(clubMember.userId, message);

  return (
    <MessagesChat
      messages={clubMember.messages}
      userFullName={`${clubMember.firstName} ${clubMember.lastName}`}
      userMedia={clubMember.media}
      sendMessage={sendMessage}
      markMessagesAsRead={markMessagesAsRead}
    />
  );
};

export default observer(Messages);
