import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Router,
  Location,
  Redirect,
} from '@reach/router';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiX } from 'react-icons/fi';

import { BreakpointProvider } from '../hooks/useBreakpoints';
import initializeSocket from '../services/SocketService';
import InstanceConfigStore from '../stores/InstanceConfigStore';
import AppStateStore from '../stores/AppStateStore';
import FeatureSwitchStore from '../stores/FeatureSwitchStore';
import AuthStore from '../stores/AuthStore';
import TenantStore from '../stores/TenantStore';
import NLogo from '../components/-common/NLogo';
import Auth from '../components/@Auth';
import Privacy from '../components/@Privacy';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import MyHome from '../components/@MyHome';
import ClubHub from '../components/@ClubHub';
import Donor from '../components/@Donor';
import Campaigns from '../components/@Campaigns';
import Integrations from '../components/@Integrations';
import Audit from '../components/@Audit';
import Settings from '../components/@Settings';
import Profile from '../components/@Profile';
import OauthComplete from '../components/OauthComplete';
import NewCampaignModal from '../components/-modals/NewCampaignModal';
import NewUserModal from '../components/-modals/NewUserModal';
import NewPlanModal from '../components/-modals/NewPlanModal';
import NewTeamModal from '../components/-modals/NewTeamModal';
import NewTierModal from '../components/-modals/NewTierModal';
import NewRewardModal from '../components/-modals/NewRewardModal';

import '../styles/kaTableOverrides.scss';
import './App.scss';

const mediaQueries = { isMobile: '(max-width: 568px)' };

const ToastClose = ({ closeToast }) => (
  <FiX
    size={18}
    onClick={closeToast}
  />
);

toast.configure({
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 5000,
  closeButton: <ToastClose />,
  className: 'nickel-toast',
});

initializeSocket();

const App = observer(() => {
  const [
    settingsOpen,
    setSettingsOpen,
  ] = useState(false);
  const [
    profileOpen,
    setProfileOpen,
  ] = useState(false);

  const rootRoutes = [
    <Privacy
      path="privacy"
      key="priv"
    />,
  ];

  const adminRoutes = AuthStore.authenticated ?
    (FeatureSwitchStore.enabledSwitches['tactical-crm'] ?
      rootRoutes.concat([
        <MyHome
          path="home/*"
          key="mh"
        />,
      ]) :
      rootRoutes
    )
      .concat([
        <Campaigns
          path="campaigns/*"
          key="ca"
        />,
        <Audit
          path="audit/*"
          key="au"
        />,
        <OauthComplete
          path="oauth/:provider/*"
          key="oc"
        />,
        <Settings
          path="settings/*"
          key="s"
        />,
        <Profile
          path="profile/*"
          key="p"
        />,
      ])
      .concat(
        FeatureSwitchStore.enabledSwitches['tactical-crm'] ?
          (
            <ClubHub
              path="club/*"
              key="ch"
            />
          ) :
          null
      )
      .concat(
        FeatureSwitchStore.enabledSwitches.integrations ?
          (
            <Integrations
              path="integrations/*"
              key="int"
            />
          ) :
          null
      ) :
    rootRoutes.concat([<Auth path="auth/*" />]);

  const donorRoutes = AuthStore.authenticated ?
    rootRoutes.concat([
      <Profile
        path="profile/*"
        key="p"
      />,
      <Donor
        path="donor/*"
        key="d"
      />,
    ]) :
    rootRoutes.concat([<Auth path="auth/*" />]);

  const routes = AuthStore.isAdminUser ? adminRoutes : donorRoutes;

  let redirect;

  if (AuthStore.authenticated != null) {
    redirect = AuthStore.authenticated ?
      (
        <Redirect
          from="*"
          to={AuthStore.isAdminUser ? 'campaigns/stats' : 'donor/contributions'}
          noThrow
        />
      ) :
      (
        <Redirect
          from="*"
          to="auth"
          noThrow
        />
      );
  }

  const modals = AuthStore.authenticated ?
    [
      <NewCampaignModal key="ncm" />,
      <NewUserModal key="num" />,
      <NewTeamModal key="ntm" />,
      <NewTierModal key="ntim" />,
      <NewRewardModal key="nrm" />,
      <NewPlanModal key="npm" />,
    ] :
    null;

  const {
    loading: instanceLoading,
    disabled,
  } = InstanceConfigStore || {};
  const { authenticated } = AuthStore || {};
  const { tenant } = TenantStore || {};

  const notAuthenticated = authenticated == null;
  const missingTenant = authenticated && !tenant.tenantId;

  if (instanceLoading || (!disabled && (notAuthenticated || missingTenant))) {
    return (
      <div className="loading">
        <NLogo height={168} />
      </div>
    );
  }

  let loadingOverlay;

  if (AppStateStore.loading) {
    loadingOverlay = (
      <div className="loading-overlay">
        <NLogo height={168} />
      </div>
    );
  }

  return (
    <>
      <div className="main-wrapper">
        <Location>
          <Header />
        </Location>
        <div className="body-wrapper">
          {AuthStore.isAdminUser && (
            <Location>
              {({
                location,
                navigate,
              }) => (
                <Sidebar
                  location={location}
                  navigate={navigate}
                  settingsOpen={settingsOpen}
                  setSettingsOpen={setSettingsOpen}
                  profileOpen={profileOpen}
                  setProfileOpen={setProfileOpen}
                />
              )}
            </Location>
          )}
          <div className="body">
            <Router>
              {routes}
              {redirect}
            </Router>
            {AuthStore.isAdminUser && (
              modals
            )}
          </div>
        </div>
      </div>
      {loadingOverlay}
    </>
  );
});

export default () => (
  <BreakpointProvider queries={mediaQueries}>
    <App />
  </BreakpointProvider>
);
