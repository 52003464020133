import {
  observable,
  computed,
  action,
  autorun,
} from 'mobx';
import { toast } from 'react-toastify';
import { request } from '@/utils';
import { sortValues } from '@/constants/sortValues';
import AuthStore from './AuthStore';
import AppStateStore from './AppStateStore';
import uploadFile from '../services/FileUploadService';

export const categories = [
  { value: 'Points-Based', label: 'Points-Based' },
  { value: 'Time-Based', label: 'Time-Based' },
  { value: 'Amount-Based', label: 'Amount-Based' },
];

const searchAchievements = searchText => a => {
  const search = searchText.toLowerCase();
  const matchesTitle = a.title.toLowerCase().includes(search);
  const matchesDescription = a.description.toLowerCase().includes(search);
  return matchesTitle || matchesDescription;
};

export const filterOptions = [{ value: 'All', label: 'All' }].concat(categories);

const filterAchievements = filter => a => {
  if (filter.value === 'All') return true;
  return a.category === filter.value;
};

export const sortOptions = [
  { label: sortValues.ALPH_ASC, value: sortValues.ALPH_ASC },
  { label: sortValues.ALPH_DESC, value: sortValues.ALPH_DESC },
  { label: sortValues.MOST_ACHIEVED, value: sortValues.MOST_ACHIEVED },
  { label: sortValues.LEAST_ACHIEVED, value: sortValues.LEAST_ACHIEVED },
];

const sortAchievements = sort => (a1, a2) => {
  const title1 = a1.title.toUpperCase();
  const title2 = a2.title.toUpperCase();

  if (sort.value === sortValues.ALPH_ASC) {
    return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
  } else if (sort.value === sortValues.ALPH_DESC) {
    return title1 > title2 ? -1 : title1 < title2 ? 1 : 0;
  } else if (sort.value === sortValues.MOST_ACHIEVED) {
    if (a1.totalUsers === a2.totalUsers) {
      return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
    } else {
      return a2.totalUsers - a1.totalUsers;
    }
  } else if (sort.value === sortValues.LEAST_ACHIEVED) {
    if (a1.totalUsers === a2.totalUsers) {
      return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
    } else {
      return a1.totalUsers - a2.totalUsers;
    }
  }
};

class AchievementsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchAchievements();
      } else {
        this.clear();
      }
    });
  }

  @observable rawAchievements = [];

  @computed get allAchievements() {
    return this.rawAchievements;
  }

  @computed get allAchievementsByCategory() {
    return this.allAchievements.reduce((acc, next) => {
      if (acc[next.category]) acc[next.category].push(next);
      else acc[next.category] = [next];
      return acc;
    }, {});
  }

  @computed get achievements() {
    return this.allAchievements
      .filter(filterAchievements(this.filter))
      .filter(searchAchievements(this.search))
      .sort(sortAchievements(this.sort));
  }

  @computed get achievementsByCategory() {
    return this.achievements.reduce((acc, next) => {
      if (acc[next.category]) acc[next.category].push(next);
      else acc[next.category] = [next];
      return acc;
    }, {});
  }

  @observable achievementCategory;

  @action setAchievementCategory(category) {
    this.achievementCategory = category;
  }

  @computed get achievementsForCategory() {
    if (!this.achievementCategory) return this.allAchievements;
    return this.allAchievements.filter(a => a.category === this.achievementCategory);
  }

  @action async fetchAchievements() {
    try {
      const achievements = await request.get('/v1/achievements');
      this.rawAchievements = achievements;
      return achievements;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async uploadFile(file) {
    try {
      const url = await uploadFile(file, 'achievement');
      return url;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addAchievement(achievementsObject, file) {
    AppStateStore.setLoading(true);
    try {
      if (file) {
        let url = await this.uploadFile(file);
        url = url.split('?')[0];
        const achievementsObj = { ...achievementsObject, media: url };
        const newAchievements = await request.post('/v1/achievements', {
          body: achievementsObj,
        });
        this.rawAchievements = this.rawAchievements.concat(newAchievements);
        AppStateStore.setLoading(false);
        toast('Achievement created!', { autoClose: 3000 });
        return true;
      } else {
        const newAchievements = await request.post('/v1/achievements', {
          body: achievementsObject,
        });
        this.rawAchievements = this.rawAchievements.concat(newAchievements);
        AppStateStore.setLoading(false);
        toast('Achievement created!', { autoClose: 3000 });
        return true;
      }
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error creating achievement.');
      return false;
    }
  }

  @action async updateAchievement(achievementObject) {
    try {
      AppStateStore.setLoading(true);
      const { achievementId, title, description, category, threshold } = achievementObject;
      const updates = { achievementId, title, description, category, threshold };
      const updatedAchievement = await request.put(`/v1/achievements/${achievementId}`, {
        body: updates,
      });
      this.updateAchievementInPlace(updatedAchievement);
      toast('Achievement updated.');
      AppStateStore.setLoading(false);
      return updatedAchievement;
    } catch (err) {
      toast('Error updating achievement.');
      AppStateStore.setLoading(false);
      console.warn(err);
    }
  }

  @action updateAchievementInPlace(updatedAchievement) {
    this.rawAchievements = this.rawAchievements.map(a => {
      if (a.achievementId === updatedAchievement.achievementId) return updatedAchievement;
      return a;
    });
  }

  @action async deleteAchievement(achievementId) {
    try {
      await request.delete(`/v1/achievements/${achievementId}`);
      this.rawAchievements = this.rawAchievements.filter(a => a.achievementId !== achievementId);
      toast('Achievement removed.');
    } catch (err) {
      toast('Error removing achievement.');
      console.warn(err);
    }
  }

  // MODAL
  @observable showNewAchievementModal = false;

  @action openNewAchievementModal = () => (this.showNewAchievementModal = true);

  @action closeNewAchievementModal = () => (this.showNewAchievementModal = false);

  // SEARCH
  @observable search = '';

  @action setSearch = search => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter = filter => (this.filter = filter);

  @observable sort = sortOptions[0];

  @action setSort = sort => (this.sort = sort);

  // CLEANUP
  @action clear() {
    this.rawAchievements = [];
  }
}

export default new AchievementsStore();
