import React, { useRef } from 'react';
import { FiX } from 'react-icons/fi';

import Spinner from '../Spinner';

import './FullScreenModal.scss';

function FullScreenModal({
  hideClose, close, loading, isOpen, children, closeOnClickOutside = false,
}) {
  const backgroundRef = useRef();
  const closeButton = hideClose ?
    null :
    (
      <div
        className="close-button"
        onClick={close}
      >
        <FiX size={30} />
      </div>
    );

  const loadingOverlay = loading ?
    (
      <div className="loading-overlay">
        <Spinner size={256} />
      </div>
    ) :
    null;

  const backgroundClose = e => {
    if (e.target === backgroundRef.current) {
      close();
    }
  };

  return (
    <div
      className={`fullscreen-modal${isOpen ? ' open' : ''}`}
      ref={backgroundRef}
      onClick={closeOnClickOutside ? backgroundClose : null}
    >
      {children}
      {closeButton}
      {loadingOverlay}
    </div>
  );
}

export default FullScreenModal;
