import React from 'react';
import {
  Router,
  Redirect,
} from '@reach/router';
import Home from './Home';

const Donor = () => (
  <Router>
    <Home path="/" />
    <Redirect
      from="*"
      to="/donor"
      noThrow
    />
  </Router>
);

export default Donor;
