import {
  observable,
  computed,
  action,
  autorun,
} from 'mobx';
import { toast } from 'react-toastify';
import { request } from '../utils';
import AuthStore from './AuthStore';

class ExtendedAddressesStore {
  constructor() {
    autorun(() => {
      if (AuthStore.DonorAPIReady) {
        this.fetchExtendedAddresses();
      } else {
        this.clear();
      }
    });
  }

  @observable rawAddresses = {};

  @observable loading = false;

  @computed get addresses() {
    return this.rawAddresses;
  }

  @action async fetchExtendedAddresses() {
    try {
      const addresses = await request.dwpAuthenticated.get('/v1/users/address/extended');

      this.rawAddresses = addresses;

      return addresses;
    } catch (err) {
      console.log(err);

      throw err;
    }
  }

  @action async updateExtendedAddress(addressDetails) {
    try {
      this.loading = true;

      await request.dwpAuthenticated.put(
        '/v1/users/address/extended',
        { body: addressDetails }
      );

      await this.fetchExtendedAddresses();

      this.loading = false;

      toast('Successfully updated address details.');

      return;
    } catch (err) {
      this.loading = false;

      toast.error(err);

      throw err;
    }
  }

  @action clear() {
    this.rawAddresses = {};
  }
}

export default new ExtendedAddressesStore();
