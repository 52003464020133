import {
  observable,
  computed,
  action,
  autorun,
} from 'mobx';
import { request } from '../utils';
import AuthStore from './AuthStore';

class ProfileStore {
  constructor() {
    autorun(() => {
      if (AuthStore.DonorAPIReady || AuthStore.AdminAPIReady) {
        this.fetchProfile();
      } else {
        this.clear();
      }
    });
  }

  @observable rawProfile = {};

  @computed get profile() {
    return this.rawProfile;
  }

  @action async fetchProfile() {
    try {
      const profile = await request.dwpAuthenticated.get(
        '/v1/profile',
        { headers: { 'X-Nickel-User-Id': AuthStore.userId } }
      );

      this.rawProfile = profile;

      return profile;
    } catch (err) {
      console.warn(err);

      throw err;
    }
  }

  @action clear() {
    this.rawProfile = {};
  }
}

export default new ProfileStore();
