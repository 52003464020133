import React from 'react';
import { observer } from 'mobx-react';

import ContributionsTable from '@/common/ContributionsTable';
import SummaryNumbers from '@/common/SummaryNumbers';
import MembershipTrend from './MembershipTrend';

import './Contributions.scss';

const Contributions = ({ clubMember }) => (
  <div className="contributions">
    <SummaryNumbers
      YTDContributions={clubMember.YTDContributions}
      lifetimeContributions={clubMember.lifetimeContributions}
      totalYearsOfMembership={clubMember.totalYearsOfMembership}
      currentRank={clubMember.currentRank}
      totalRank={clubMember.totalRank}
      totalPriorityPoints={clubMember.totalPriorityPoints}
    />
    <div className="metrics">
      <MembershipTrend transactions={clubMember?.transactions} totalYearsOfMembership={clubMember?.totalYearsOfMembership} />
      <div className="contributions-table">
        <ContributionsTable transactions={clubMember?.transactions} />
      </div>
    </div>
  </div>
);

export default observer(Contributions);
