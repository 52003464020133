import {
  observable, computed, action, autorun,
} from 'mobx';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import {
  request,
  downloadURLContents,
  formatCardBrand,
} from '@/utils';
import { sortValues } from '@/constants/sortValues';
import { billingCycles } from '@/constants/billingCycles';
import AppStateStore from './AppStateStore';
import AuthStore from './AuthStore';
import CampaignsStore from './CampaignsStore';
import RewardsStore from './RewardsStore';

export const filterOptions = [
  {
    label: 'All', value: 'All',
  },
  {
    label: 'Membership Renewals', value: 'Membership Renewals',
  },
  {
    label: 'Campaign Donations', value: 'Campaign Donations',
  },
  {
    label: 'Refunds', value: 'Refunds',
  },
];

const filterTransactions = filter => p => {
  if (filter.value === 'Membership Renewals') {
    return p?.type === 'renewal';
  }

  if (filter.value === 'Campaign Donations') {
    return p?.type === 'campaign donation';
  }

  if (filter.value === 'Refunds') {
    return !!p?.nickelMetadata?.refundId;
  }

  return true;
};

export const sortOptions = [
  {
    label: sortValues.NEWEST, value: sortValues.NEWEST,
  },
  {
    label: sortValues.OLDEST, value: sortValues.OLDEST,
  },
  {
    label: sortValues.AMOUNT_HIGH_TO_LOW, value: sortValues.AMOUNT_HIGH_TO_LOW,
  },
  {
    label: sortValues.AMOUNT_LOW_TO_HIGH, value: sortValues.AMOUNT_LOW_TO_HIGH,
  },
  {
    label: sortValues.NAME_A_TO_Z, value: sortValues.NAME_A_TO_Z,
  },
  {
    label: sortValues.NAME_Z_TO_A, value: sortValues.NAME_Z_TO_A,
  },
];

const sortTransactions = sort => (t1, t2) => {
  const name1 = t1.userMetadata?.name?.toLowerCase().trim();
  const name2 = t2.userMetadata?.name?.toLowerCase().trim();
  let amountSort1 = t1.amountMetadata?.totalDonationAmount;
  let amountSort2 = t2.amountMetadata?.totalDonationAmount;

  if (sort.value === sortValues.NEWEST) {
    return t2.date.valueOf() - t1.date.valueOf();
  }

  if (sort.value === sortValues.OLDEST) {
    return t1.date.valueOf() - t2.date.valueOf();
  }

  if (sort.value === sortValues.AMOUNT_HIGH_TO_LOW) {
    if (t1?.nickelMetadata?.refundId) amountSort1 = 0;

    if (t2?.nickelMetadata?.refundId) amountSort2 = 0;

    return amountSort1 > amountSort2 ? -1 : amountSort1 < amountSort2 ? 1 : 0;
  }

  if (sort.value === sortValues.AMOUNT_LOW_TO_HIGH) {
    if (t1?.nickelMetadata?.refundId) amountSort1 = 99999999999;

    if (t2?.nickelMetadata?.refundId) amountSort2 = 99999999999;

    return amountSort1 < amountSort2 ? -1 : amountSort1 > amountSort2 ? 1 : 0;
  }

  if (sort.value === sortValues.NAME_A_TO_Z) {
    if (name1 === '(anonymous)' && name2 === '(anonymous)') return 0;

    if (name1 === '(anonymous)') return 1;

    if (name2 === '(anonymous)') return -1;

    if (name1 < name2) return -1;

    if (name1 > name2) return 1;

    return 0;
  }

  if (sort.value === sortValues.NAME_Z_TO_A) {
    if (name1 === '(anonymous)' && name2 === '(anonymous)') return 0;

    if (name1 === '(anonymous)') return 1;

    if (name2 === '(anonymous)') return -1;

    if (name2 < name1) return -1;

    if (name2 > name1) return 1;

    return 0;
  }
};

const searchTransactions = search => t => {
  // Date Stamp,
  // Name,
  // Last four Digits of CC used
  // with card type (e.g. Visa, AMEX)
  // or check number,
  // transaction amount,
  // the purpose of the transaction (Membership renewal, Campaign Name etc).
  // Email
  // and/or address collected
  const searchText = search.toLowerCase();
  const matchesDate =
    format(t.date, 'M/d/yyyy').includes(searchText) ||
    format(t.date, 'MMM do, yyyy').includes(searchText);

  const matchesName = t?.userMetadata?.name?.toLowerCase().includes(searchText);

  const matchesCard =
    t?.processorMetadata?.card?.brand?.toLowerCase().includes(searchText) ||
    t?.processorMetadata?.card?.last4?.toLowerCase().includes(searchText);

  const matchesAmount = String(t?.amountMetadata?.totalDonationAmount)
    ?.toLowerCase()
    .includes(searchText);

  const matchesPurpose =
    t.type === 'renewal' ?
      t?.type?.toLowerCase().includes(searchText) :
      t?.nickelMetadata?.campaign?.title?.toLowerCase().includes(searchText);

  const matchesEmail = t?.userMetadata?.email?.toLowerCase().includes(searchText);

  return (
    matchesDate || matchesName || matchesCard || matchesAmount || matchesPurpose || matchesEmail
  );
};

class TransactionsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchTransactions();
      } else if (AuthStore.DonorAPIReady) {
        this.fetchDonorTransactions();
        this.fetchDonorChartTransactions();
        this.fetchDonorTransactionsCSV();
      } else {
        this.clear();
      }
    });
  }

  @observable rawTransactions = [];

  @computed get transactions() {
    const mappedTransactions = this.rawTransactions.map(tx => {
      const date = new Date(tx.date);
      const amountMetadata = {
        ...(tx?.amountMetadata || {}),
        totalDonationAmount: tx?.amountMetadata?.totalDonationAmount,
        nickelCommissionAmount: tx?.amountMetadata?.nickelCommissionAmount,
        paymentProcessingAmount: tx?.amountMetadata?.paymentProcessingAmount,
      };

      const brand = formatCardBrand(tx?.processorMetadata?.card?.brand);
      const processorMetadata = {
        ...(tx?.processorMetadata || {}),
        card: {
          ...(tx?.processorMetadata?.card || {}), brand,
        },
      };

      const campaign = CampaignsStore.campaigns.find(
        c => c.campaignId === tx?.nickelMetadata?.campaignId
      );
      const tier = campaign?.tiers?.find(t => t.tierId === tx.nickelMetadata.tierId);
      const reward = tier && tier.rewardId ?
        RewardsStore.allRewards.find(r => r.rewardId === tier.rewardId) :
        null;

      return {
        ...tx,
        date,
        amountMetadata,
        processorMetadata,
        campaign,
        tier,
        reward,
        key: tx.transactionId,
      };
    });

    const refundTransactions = mappedTransactions
      ?.map(t => t?.refund && {
        ...t?.refund, transaction: t, userMetadata: t?.userMetadata,
      })
      ?.filter(Boolean)
      ?.map(r => ({
        ...r,
        date: new Date(r?.date),
        amountMetadata: { totalDonationAmount: -1 * r.amount },
        processorMetadata: { card: {} },
        key: r?.refundId,
      }));
    const allTransactions = mappedTransactions.concat(refundTransactions);

    return allTransactions
      .filter(filterTransactions(this.filter))
      .filter(searchTransactions(this.search))
      .sort(sortTransactions(this.sort));
  }

  @computed get totalRevenue() {
    return this.transactions.reduce(
      (acc, next) => acc + next?.amountMetadata?.totalDonationAmount * 1,
      0
    );
  }

  @computed get totalTransactions() {
    return this.transactions.filter(t => !t?.nickelMetadata?.refundId).length;
  }

  @computed get totalRefunds() {
    return this.transactions.filter(t => !!t?.nickelMetadata?.refundId).length;
  }

  @computed get membershipRenewals() {
    return this.transactions.filter(t => t.type === 'renewal' && !t?.nickelMetadata?.refundId)
      .length;
  }

  @computed get campaignDonations() {
    return this.transactions.filter(
      t => t.type === 'campaign donation' && !t?.nickelMetadata?.refundId
    ).length;
  }

  @action async fetchTransactions() {
    try {
      const transactions = await request.get('/v1/transactions?offset=0');

      this.rawTransactions = transactions || [];

      return transactions;
    } catch (err) {
      console.warn(err);
    }
  }

  @action updateTransactionInPlace(updatedTransaction) {
    this.rawTransactions = this.rawTransactions.map(t => {
      if (t.transactionId === updatedTransaction.transactionId) {
        return updatedTransaction;
      }

      return t;
    });
  }

  @action addTransactionInPlace(newTransaction) {
    this.rawTransactions = this.rawTransactions.concat(newTransaction);
  }

  @action async refundTransaction(transactionId) {
    AppStateStore.setLoading(true);

    try {
      const updatedTransaction = await request.post(`/v1/transactions/${transactionId}/refund`);

      this.updateTransactionInPlace(updatedTransaction);
      AppStateStore.setLoading(false);
      toast('Transaction refunded successfully.', { autoClose: 3000 });

      return updatedTransaction;
    } catch (err) {
      console.warn(err);
      toast('Error refunding transaction.');
      AppStateStore.setLoading(false);
    }
  }

  @action async sendReceiptForTransaction(transactionId) {
    AppStateStore.setLoading(true);

    try {
      await request.post(`/v1/transactions/${transactionId}/sendreceipt`);
      AppStateStore.setLoading(false);
      toast('Receipt sent successfully.', { autoClose: 3000 });
    } catch (err) {
      console.warn(err);
      toast('Error sending receipt.');
      AppStateStore.setLoading(false);
    }
  }

  @action async getTransactionsCSV() {
    AppStateStore.setLoading(true);

    try {
      const { url } = await request.get('/v1/transactions?type=csv');

      downloadURLContents(url);
    } catch (err) {
      toast('Error exporting transactions.');
    }
    AppStateStore.setLoading(false);
  }

  // INDIVIDUAL MEMBERS TRANSACTIONS

  @observable memberTransactions = {};

  @observable memberTransactionRequests = {};

  @action async fetchTransactionsForMember(userId) {
    try {
      let memberTransactions = [];

      if (this.memberTransactionRequests[userId]) {
        memberTransactions = await this.memberTransactionRequests[userId];
        this.memberTransactionRequests = {
          ...this.memberTransactionRequests, [userId]: null,
        };
      } else {
        const transactionsRequest = request.get(`/v1/transactions?userId=${userId}`);

        this.memberTransactionRequests = {
          ...this.memberTransactionRequests,
          [userId]: transactionsRequest,
        };
        memberTransactions = await transactionsRequest;
        this.memberTransactions[userId] = memberTransactions.sort((a, b) => (a.date < b.date ? 1 : a.date > b.date ? -1 : 0));
        this.memberTransactionRequests = {
          ...this.memberTransactionRequests, [userId]: null,
        };
      }

      return memberTransactions;
    } catch (err) {
      console.warn(err);
    }
  }

  @observable rawDonorTransactions = [];

  @computed get donorTransactions() {
    return this.rawDonorTransactions
      .map(singleTransaction => ({
        ...singleTransaction,
        billingCycle: billingCycles[singleTransaction.billingCycle] || '-',
        amountMetadata: { totalDonationAmount: singleTransaction.amount },
        date: singleTransaction.createdAt,
      }))
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
  }

  @action async fetchDonorTransactions() {
    const transactions = await request.dwpAuthenticated.get('/v1/payments/protected/history?startDate=1980-01-01&endDate=2050-12-30');

    this.rawDonorTransactions = transactions;
  }

  @observable rawDonorChartTransactions = [];

  @computed get donorChartTransactions() {
    return this.rawDonorChartTransactions.map(transactionsGroup => ({
      ...transactionsGroup,
      data: transactionsGroup.data.map(transactionsData => ({
        ...transactionsData,
        donations: transactionsData.donations ? parseInt(transactionsData.donations, 10) / 100 : 0,
      })).sort((a, b) => (a.month > b.month ? 1 : -1)),
    }));
  }

  @action async fetchDonorChartTransactions() {
    const transactions = await request.dwpAuthenticated.get('/v1/payments/protected/history/charts');

    this.rawDonorChartTransactions = transactions;
  }

  @observable rawDonorTransactionsCSV = '';

  @computed get donorTransactionsCSV() {
    return this.rawDonorTransactionsCSV ? atob(this.rawDonorTransactionsCSV) : '';
  }

  @action async fetchDonorTransactionsCSV() {
    const transactionsDataCSV = await request.dwpAuthenticated.get('/v1/payments/protected/history/csv?startDate=1980-01-01&endDate=2050-12-30');

    this.rawDonorTransactionsCSV = transactionsDataCSV.data;
  }

  // SEARCH
  @observable search = '';

  @action setSearch = search => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter(filter) {
    this.filter = filter;
  }

  @observable sort = sortOptions[0];

  @action setSort(sort) {
    this.sort = sort;
  }

  // CLEANUP
  @action clear() {
    this.rawTransactions = [];
  }
}

export default new TransactionsStore();
