import axios from 'axios';
import {
  API, Auth,
} from 'aws-amplify';
import { navigate } from '@reach/router';
import InstanceConfigStore from '@/stores/InstanceConfigStore';
import {
  API_HOST, API_NAME, DWP_HOST, DWP_NAME,
} from '../constants/main';

const instance = axios.create({ baseURL: `${API_HOST}` });
const dwpInstance = axios.create({ baseURL: `${DWP_HOST}` });

const makeUnpackedMethod =
  method => async (...params) => {
    const { data } = await method(...params);

    return data;
  };

const makeWrappedMethod =
  (method, apiName) => async (url, config = {}) => {
    const amplifyMethod = method === 'delete' ? 'del' : method;
    const currentSession = await Auth.currentSession();

    const configWithCustomHeaders = {
      ...config,
      headers: {
        ...(config?.headers || {}),
        'X-Nickel-Id-Token': currentSession.getIdToken().getJwtToken(),
        ...(apiName === DWP_NAME ? { 'X-Nickel-Tenant-Id': InstanceConfigStore.tenant.tenantId } : {}),
      },
    };

    try {
      return await API[amplifyMethod](apiName, url, configWithCustomHeaders);
    } catch (err) {
      if (err?.response?.status === 401) {
        await Auth.signOut();
        navigate('/auth');
      } else {
        throw err;
      }
    }
  };

const wrapper = {
  post: makeWrappedMethod('post', API_NAME),
  get: makeWrappedMethod('get', API_NAME),
  put: makeWrappedMethod('put', API_NAME),
  delete: makeWrappedMethod('delete', API_NAME),

  unauthenticated: {
    post: makeUnpackedMethod(instance.post),
    get: makeUnpackedMethod(instance.get),
    put: makeUnpackedMethod(instance.put),
    delete: makeUnpackedMethod(instance.delete),
  },

  dwp: {
    post: makeUnpackedMethod(dwpInstance.post),
    get: makeUnpackedMethod(dwpInstance.get),
    put: makeUnpackedMethod(dwpInstance.put),
    delete: makeUnpackedMethod(dwpInstance.delete),
  },

  dwpAuthenticated: {
    post: makeWrappedMethod('post', DWP_NAME),
    get: makeWrappedMethod('get', DWP_NAME),
    put: makeWrappedMethod('put', DWP_NAME),
    delete: makeWrappedMethod('delete', DWP_NAME),
  },

  bare: {
    post: axios.post,
    get: axios.get,
    put: axios.put,
    delete: axios.delete,
  },
};

export default wrapper;
