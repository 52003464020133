import React, {
  useState, useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { MdClose } from 'react-icons/md';
import { GoPlus } from 'react-icons/go';
import {
  FiEdit2, FiX, FiCheck,
} from 'react-icons/fi';

import Page from '@/common/Page';
import Button from '@/common/Button';
import currencyFormatter from '@/utils/currencyFormatter';
import CurrencyInput from '@/common/CurrencyInput';
import BenefitsList from '@/common/BenefitsList';
import Input from '@/common/Input';
import { mockedMembershipBenefits } from '@/constants/mockedMembershipBenefits';
import MembershipsStore from '@/stores/MembershipsStore';
import ConfirmRemoveLevelModal from './ConfirmRemoveLevelModal';
import useMembership from './useMembership';
import PerksTable from './PerksTable';
import NewPerkModal from './NewPerkModal';
import TeamPerksModal from './TeamPerksModal';

import './Level.scss';

function MembershipDetails({
  level, annualContribution, oneTimeContribution, membership,
}) {
  const [
    editLevel,
    setEditLevel,
  ] = useState(false);
  const [
    editAnnual,
    setEditAnnual,
  ] = useState(false);
  const [
    editOneTime,
    setEditOneTime,
  ] = useState(false);

  const [
    localLevel,
    setLocalLevel,
  ] = useState();
  const [
    localAnnual,
    setLocalAnnual,
  ] = useState();
  const [
    localOneTime,
    setLocalOneTime,
  ] = useState();

  const {
    membershipId,
    level: originalLevel,
    annualContribution: originalAnnual,
    oneTimeContribution: originalOneTime,
  } = membership || {};

  useEffect(() => {
    setLocalLevel(level);
    setLocalAnnual(annualContribution * 100);
    setLocalOneTime(oneTimeContribution * 100);
  }, [
    level,
    annualContribution,
    oneTimeContribution,
  ]);

  const toggleLevelEdit = () => {
    setEditLevel(!editLevel);
  };

  const toggleAnnualEdit = () => {
    setEditAnnual(!editAnnual);
  };

  const toggleOneTimeEdit = () => {
    setEditOneTime(!editOneTime);
  };

  const saveEdit = (key, value) => () => {
    MembershipsStore.updateMembership({
      membershipId, [key]: value,
    });

    if (key === 'level') toggleLevelEdit();

    if (key === 'annualContribution') toggleAnnualEdit();

    if (key === 'oneTimeContribution') toggleOneTimeEdit();
  };

  const LevelComponent = () => (editLevel ?
    (
      <div className="level-info-row">
        <div className="info-section">
          <div className="title-line">Level</div>
          <div className="input-container">
            <Input
              value={localLevel}
              onChange={value => setLocalLevel(value)}
            />
            <FiX
              className="cancel-value"
              onClick={toggleLevelEdit}
            />
            <FiCheck
              className="save-value"
              style={{ display: localLevel === originalLevel * 100 ? 'none' : 'initial' }}
              onClick={saveEdit('level', localLevel)}
            />
          </div>
        </div>
      </div>
    ) :
    (
      <div className="level-info-row">
        <div className="info-section">
          <div className="title-line">Level</div>
          <div className="detail-line">{level}</div>
        </div>
        <FiEdit2
          className="edit-value"
          onClick={toggleLevelEdit}
        />
      </div>
    ));

  const AnnualComponent = () => (editAnnual ?
    (
      <div className="info-row">
        <div className="info-title">Annual Contribution</div>
        <div className="input-container">
          <CurrencyInput
            inputContainerStyles={{ width: '90%' }}
            value={localAnnual}
            onChange={value => setLocalAnnual(value)}
          />
          <FiX
            className="cancel-value"
            onClick={toggleAnnualEdit}
          />
          <FiCheck
            className="save-value"
            style={{ display: localAnnual === originalAnnual * 100 ? 'none' : 'initial' }}
            onClick={saveEdit('annualContribution', localAnnual)}
          />
        </div>
      </div>
    ) :
    (
      <div className="info-row">
        <div className="info-title">Annual Contribution</div>
        <div className="info-text">{currencyFormatter(annualContribution)}</div>
        <FiEdit2
          className="edit-value"
          onClick={toggleAnnualEdit}
        />
      </div>
    ));

  const OneTimeConponent = () => (editOneTime ?
    (
      <div className="info-row">
        <div className="info-title">One-Time Contribution</div>
        <div className="input-container">
          <CurrencyInput
            inputContainerStyles={{ width: '90%' }}
            value={localOneTime}
            onChange={value => setLocalOneTime(value)}
          />
          <FiX
            className="cancel-value"
            onClick={toggleOneTimeEdit}
          />
          <FiCheck
            className="save-value"
            style={{ display: localOneTime === originalOneTime * 100 ? 'none' : 'initial' }}
            onClick={saveEdit('oneTimeContribution', localOneTime)}
          />
        </div>
      </div>
    ) :
    (
      <div className="info-row">
        <div className="info-title">One-Time Contribution</div>
        <div className="info-text">{currencyFormatter(oneTimeContribution)}</div>
        <FiEdit2
          className="edit-value"
          onClick={toggleOneTimeEdit}
        />
      </div>
    ));

  return (
    <div className="level-details">
      <LevelComponent />
      <AnnualComponent />
      <OneTimeConponent />
    </div>
  );
}

const Level = ({ membershipId }) => {
  const membership = useMembership(membershipId);
  const [
    localMembership,
    setLocalMembership,
  ] = useState();

  useEffect(() => {
    if (membership?.membershipId) setLocalMembership(membership);
  }, [membership]);

  const [
    newPerkModalOpen,
    setNewPerkModalOpen,
  ] = useState(false);
  const openNewPerkModal = () => setNewPerkModalOpen(true);
  const closeNewPerkModal = () => setNewPerkModalOpen(false);
  const [
    newTeamPerkModalOpen,
    setNewTeamPerkModalOpen,
  ] = useState(false);
  const openNewTeamPerkModal = () => setNewTeamPerkModalOpen(true);
  const closeNewTeamPerkModal = () => setNewTeamPerkModalOpen(false);

  const [
    removeModalOpen,
    setRemoveModalOpen,
  ] = useState(false);
  const openRemoveModal = () => setRemoveModalOpen(true);
  const closeRemoveModal = () => setRemoveModalOpen(false);

  return (
    <Page
      titleOpts={{
        title: 'Level',
        backButton: true,
      }}
      buttons={[
        <Button
          text="Remove"
          icon={MdClose}
          key="remove"
          onClick={openRemoveModal}
        />,
        <Button
          text="Add General Perk"
          icon={GoPlus}
          key="add-perk"
          onClick={openNewPerkModal}
        />,
        <Button
          text="Change Team Perks"
          icon={GoPlus}
          key="change-tickets"
          onClick={openNewTeamPerkModal}
        />,
      ]}
    >
      <div className="level-detail">
        <MembershipDetails
          level={localMembership?.level}
          annualContribution={localMembership?.annualContribution}
          oneTimeContribution={localMembership?.oneTimeContribution}
          membership={membership}
        />
        <div>
          <BenefitsList
            shownMembershipId={2}
            memberships={mockedMembershipBenefits}
            hideMembershipSelector
          />
          <PerksTable
            perks={membership?.perks || []}
            membership={membership}
          />
        </div>
      </div>
      <NewPerkModal
        isOpen={newPerkModalOpen}
        close={closeNewPerkModal}
        membership={membership}
      />
      <TeamPerksModal
        isOpen={newTeamPerkModalOpen}
        close={closeNewTeamPerkModal}
        membership={membership}
      />
      <ConfirmRemoveLevelModal
        isOpen={removeModalOpen}
        close={closeRemoveModal}
        membershipId={membershipId}
      />
    </Page>
  );
};

export default observer(Level);
