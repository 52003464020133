import React from 'react';
import {
  Router, Redirect,
} from '@reach/router';

import Personal from './Personal';

import './Profile.scss';

export default function Profile() {
  return (
    <Router>
      <Personal
        path="personal"
        default
      />
      <Redirect
        from="/"
        to="/profile/personal"
        noThrow
      />
      <Redirect
        from="profile"
        to="/profile/personal"
        noThrow
      />
    </Router>
  );
}
