import React, {
  useState,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { useLocation } from '@reach/router';
import {
  FiExternalLink,
  FiCreditCard,
} from 'react-icons/fi';

import ClubMembersStore from '@/stores/ClubMembersStore';
import Page from '@/common/Page';
import TabBar from '@/common/TabBar';
import Button from '@/common/Button';
import Spinner from '@/common/Spinner';
import DonorDetailsBox from '@/common/DonorDetailsBox';
import useClubMember from './useClubMember';
import Contributions from './Contributions';
import Messages from './Messages';
import Notes from './Notes';
import AddPaymentModal from './AddPaymentModal';
// import Tickets from './Tickets';

import './ClubMember.scss';

const tabs = [
  {
    id: 'Contributions',
    title: 'Contributions',
    component: Contributions,
  },
  // {
  //   id: 'Tickets',
  //   title: 'Tickets',
  //   component: Tickets,
  // },
  {
    id: 'Messages',
    title: 'Messages',
    component: Messages,
  },
  {
    id: 'Notes',
    title: 'Notes',
    component: Notes,
  },
];

const ClubMember = ({ userId }) => {
  const { search } = useLocation();
  const clubMember = useClubMember(userId);
  const [
    selectedTab,
    setSelectedTab,
  ] = useState(0);
  const [
    addPaymentModalOpen,
    setAddPaymentModalOpen,
  ] = useState(false);

  const openAddPaymentModal = () => setAddPaymentModalOpen(true);
  const closeAddPaymentModal = () => setAddPaymentModalOpen(false);

  const [
    inviting,
    setInviting,
  ] = useState(false);
  const sendMobileInvite = async () => {
    setInviting(true);
    await ClubMembersStore.sendMobileInvite(userId);
    setInviting(false);
  };

  useEffect(() => {
    if (clubMember) {
      const {
        firstName, lastName, media,
      } = clubMember;

      ClubMembersStore.addToRecentlyViewed({
        userId,
        profile: {
          firstName, lastName, media,
        },
      });
    }
  }, [
    clubMember,
    userId,
  ]);

  useEffect(() => {
    if (userId && search) {
      const paramStrings = search.replace('?', '')?.split('&');
      const params = Object.fromEntries(
        paramStrings?.map(ps => {
          const [
            key,
            val,
          ] = ps?.split('=');

          return [
            key,
            val == null ? true : JSON.parse(val),
          ];
        })
      );

      if (params.pledges) setSelectedTab(0);

      // if (params.benefits) setSelectedTab(1);

      if (params.messages) setSelectedTab(1);

      if (params.notes) setSelectedTab(2);
    }
  }, [
    userId,
    search,
  ]);

  const DetailPage = tabs[selectedTab].component;

  const content = clubMember?.userId ?
    (
      <div className="club-member-detail">
        <DonorDetailsBox
          media={clubMember.media}
          firstName={clubMember.firstName}
          lastName={clubMember.lastName}
          currentMembershipLevel={clubMember.currentMembershipLevel}
          totalPriorityPoints={clubMember.totalPriorityPoints}
          email={clubMember.email}
          DOAssignment={clubMember.assignedDevelopmentOfficer ?
            `${clubMember.assignedDevelopmentOfficer.profile.firstName} ${clubMember.assignedDevelopmentOfficer.profile.lastName}` :
            'N/A'}
          DOAssignmentId={clubMember.assignedDevelopmentOfficer?.userId}
          membershipExpirationDate={clubMember.membershipExpirationDate}
          userId={clubMember.userId}
        />
        <div className="club-member-pages-wrapper">
          <div className="club-member-tab-bar-wrapper">
            <TabBar
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
          <div className="tab-detail">
            <DetailPage clubMember={clubMember} />
          </div>
        </div>
      </div>
    ) :
    (
      <div className="club-member-loading-container">
        <Spinner color="var(--primary-color)" />
      </div>
    );

  const inviteButtonBody = inviting ? <Spinner color="var(--White)" /> : 'Send Mobile Invite';

  return (
    <>
      <Page
        titleOpts={{
          title: 'Club Member',
          backButton: true,
        }}
        buttons={[
          <Button
            text="Process Payment"
            icon={FiCreditCard}
            onClick={openAddPaymentModal}
            key="payment"
          />,
          <Button
            icon={FiExternalLink}
            text={inviteButtonBody}
            onClick={sendMobileInvite}
            key="refund"
            disabled={
              !clubMember?.config?.mobileReady ||
              !clubMember?.assignedDevelopmentOfficer?.userId ||
              !clubMember?.email
            }
            style={inviting ?
              {
                opacity: 0.7, pointerEvents: 'none',
              } :
              null}
          />,
        ]}
      >
        {content}
      </Page>
      <AddPaymentModal
        clubMember={clubMember}
        isOpen={addPaymentModalOpen}
        close={closeAddPaymentModal}
      />
    </>
  );
};

export default observer(ClubMember);
