/**
 * Pluralizes a given noun based on a count, with an optional alternative plural form.
 *
 * @param {string} noun - The singular noun to pluralize.
 * @param {number} count - The count of the noun, used to determine whether to use the singular or plural form.
 * @param {string|null} [alternativePlural=null] - An optional alternative plural form to use instead of the default rules.
 * @returns {string} The pluralized form of the noun.
 *
 * @example
 * pluralize('cat', 1); // Returns: 'cat'
 * pluralize('cat', 2); // Returns: 'cats'
 * pluralize('city', 3); // Returns: 'cities'
 *
 * @example
 * // Using an alternative plural form:
 * pluralize('person', 1, 'people'); // Returns: 'person'
 * pluralize('person', 2, 'people'); // Returns: 'people'
 * pluralize('ox', 3, 'oxen'); // Returns: 'oxen'
 */
function pluralize(noun, count, alternativePlural = null) {
  // If the count is not equal to 1, pluralize the noun
  if (count !== 1) {
    // If an alternative plural form is provided, return it
    if (alternativePlural !== null) {
      return alternativePlural;
    // Otherwise, apply the default pluralization rules
    } else if (noun.endsWith('y')) {
      // Words ending in "y" become plural by replacing the "y" with "ies"
      return noun.slice(0, -1) + 'ies';
    } else if (['s', 'x', 'z', 'ch', 'sh'].some(s => noun.endsWith(s))) {
      // Words ending in "s", "x", "z", "ch", or "sh" become plural by adding "es"
      return noun + 'es';
    } else {
      // For all other cases, simply add an "s"
      return noun + 's';
    }
  }
  // If the count is equal to 1, return the noun as is (in its singular form)
  return noun;
}


export default pluralize;