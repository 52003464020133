import { observable, computed, action, autorun } from 'mobx';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { request, downloadURLContents, formatCardBrand } from '../utils';
import AuthStore from './AuthStore';
import AppStateStore from './AppStateStore';
import CampaignsStore from './CampaignsStore';
import RewardsStore from './RewardsStore';
import UsersStore from './UsersStore';
import TransactionsStore from './TransactionsStore';

export const filterOptions = [
  { label: 'All', value: 'All' },
  { label: 'Unreconciled', value: 'Unreconciled' },
  { label: 'Reconciled', value: 'Reconciled' },
];

const filterPayouts = filter => p => {
  if (filter.value === 'Unreconciled') {
    return !p?.reconciled;
  } else if (filter.value === 'Reconciled') {
    return p?.reconciled;
  } else {
    return true;
  }
};

export const sortOptions = [
  { label: 'Newest', value: 'Newest' },
  { label: 'Oldest', value: 'Oldest' },
  { label: 'Amount High to Low', value: 'Amount High to Low' },
  { label: 'Amount Low to High', value: 'Amount Low to High' },
];

const sortPayouts = sort => (p1, p2) => {
  if (sort.value === 'Newest') {
    return parseISO(p2.date).valueOf() - parseISO(p1.date).valueOf();
  } else if (sort.value === 'Oldest') {
    return parseISO(p1.date).valueOf() - parseISO(p2.date).valueOf();
  } else if (sort.value === 'Amount High to Low') {
    return p1.netRevenue > p2.netRevenue ? -1 : p1.netRevenue < p2.netRevenue ? 1 : 0;
  } else if (sort.value === 'Amount Low to High') {
    return p1.netRevenue < p2.netRevenue ? -1 : p1.netRevenue > p2.netRevenue ? 1 : 0;
  }
};

class PayoutsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchPayouts();
      } else {
        this.clear();
      }
    });
  }

  @observable rawPayouts = [];

  @computed get allPayouts() {
    const payouts = this.rawPayouts
      .filter(
        p =>
          p.payoutId &&
          (p.totalAmountProcessed ||
            p.totalAmountRefunded ||
            p.totalNickelFeeAmount ||
            p.totalProcessingFeeAmount),
      )
      .map(p => ({
        ...p,
        reconciled: p.reconciled ? parseISO(p.reconciled) : null,
        totalTransactions: (p.transactions || []).length,
        transactions: p.transactions.map(tx => {
          const dateField = tx?.date || tx?.refundDate || tx?.feeRefundDate;
          let date = parseISO(dateField);
          if (isNaN(date)) date = null;

          const name = tx.user
            ? tx.user.firstName + ' ' + tx.user.lastName
            : tx?.metadata?.name
            ? tx.metadata.name
            : '(anonymous)';
          const campaign = CampaignsStore.campaigns.find(c => c.campaignId === tx.campaignId);
          const tier = campaign?.tiers?.find(t => t.id === tx.tierId);
          const reward = tier && tier.rewardId ?
            RewardsStore.allRewards.find(r => r.rewardId === tier.rewardId) :
            null;
          const type = tx?.paymentType
            ? tx.paymentType.slice(0, 1).toUpperCase() + tx.paymentType.slice(1)
            : '';
          // Defaults for refunds
          const userMetadata = tx?.userMetadata || {};
          const amountMetadata = tx?.amountMetadata || {};
          const processorMetadata = tx?.processorMetadata
            ? {
                ...tx.processorMetadata,
                card: tx.processorMetadata?.card
                  ? {
                      ...tx.processorMetadata?.card,
                      brand: formatCardBrand(tx?.processorMetadata?.card?.brand),
                    }
                  : null,
              }
            : {};
          const transaction = tx?.refundId
            ? TransactionsStore?.transactions?.find(t => t?.transactionId === tx?.transactionId)
            : null;
          const key = tx?.feeRefundId || tx?.refundId || tx?.transactionId;
          return {
            ...tx,
            date,
            name,
            campaign,
            tier,
            reward,
            paymentType: type,
            userMetadata,
            amountMetadata,
            processorMetadata,
            transaction,
            key,
          };
        }),
        date: parseISO(p.remittanceDate),
        bankAccount: p.bank_name && p.bank_last_4 ? `${p.bank_name} - ...${p.bank_last_4}` : null,
      }))
      .sort((a, b) => b.date - a.date);
    return payouts;
  }

  @computed get payouts() {
    return this.allPayouts
      .filter(p =>
        JSON.stringify(p)
          .toLowerCase()
          .includes((this.search || '').toLowerCase()),
      )
      .filter(filterPayouts(this.filter))
      .sort(sortPayouts(this.sort));
  }

  @action async fetchPayouts() {
    try {
      const payouts = await request.get('/v1/payouts');
      this.rawPayouts = payouts;
      return payouts;
    } catch (err) {
      console.warn(err);
    }
  }

  @action updatePayoutInPlace(updatedPayout) {
    this.rawPayouts = this.rawPayouts.map(p => {
      if (p.payoutId === updatedPayout.payoutId) {
        return updatedPayout;
      }
      return p;
    });
  }

  @action async markPayoutReconciled(payoutId, optimistic) {
    if (!optimistic) AppStateStore.setLoading(true);

    let prePayout = this.rawPayouts.find(p => p.payoutId === payoutId);
    try {
      if (optimistic) {
        this.updatePayoutInPlace({
          ...prePayout,
          reconciled: parseISO().toISOString(),
          reconciledByUser: UsersStore.me,
        });
      }

      const updatedPayout = await request.put(`/v1/payouts/${payoutId}/reconcile`);
      this.updatePayoutInPlace(updatedPayout);
      if (!optimistic) {
        AppStateStore.setLoading(false);
        toast('Payout marked as reconciled!', { autoClose: 3000 });
      }
      return updatedPayout;
    } catch (err) {
      console.warn(err);
      if (optimistic) {
        this.updatePayoutInPlace(prePayout);
      }
      toast('Error marking payout as reconciled.');
      if (!optimistic) AppStateStore.setLoading(false);
    }
  }

  @action async markPayoutUnreconciled(payoutId, optimistic) {
    if (!optimistic) AppStateStore.setLoading(true);

    let prePayout = this.rawPayouts.find(p => p.payoutId === payoutId);
    try {
      if (optimistic) {
        this.updatePayoutInPlace({ ...prePayout, reconciled: null });
      }

      const updatedPayout = await request.put(`/v1/payouts/${payoutId}/unreconcile`);
      this.updatePayoutInPlace(updatedPayout);
      if (!optimistic) {
        AppStateStore.setLoading(false);
        toast('Payout marked as unreconciled!', {
          autoClose: 3000,
        });
      }
      return updatedPayout;
    } catch (err) {
      console.warn(err);
      if (optimistic) {
        this.updatePayoutInPlace(prePayout);
      }
      toast('Error marking payout as unreconciled.');
      if (!optimistic) AppStateStore.setLoading(false);
    }
  }

  @action async getPayoutsCSV() {
    AppStateStore.setLoading(true);
    try {
      const { url } = await request.get('/v1/payouts?type=csv');
      downloadURLContents(url);
    } catch (err) {
      toast('Error exporting payouts.');
    }
    AppStateStore.setLoading(false);
  }

  // SEARCH
  @observable search = '';

  @action setSearch = search => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter(filter) {
    this.filter = filter;
  }

  @observable sort = sortOptions[0];

  @action setSort(sort) {
    this.sort = sort;
  }

  // CLEANUP
  @action clear() {
    this.rawPayouts = [];
  }
}

export default new PayoutsStore();
