export const ENVIRONMENT_PREFIX = "dev.";
export const NICKEL_HOSTED_URL = "https://dev.donate.nickeldxm.com";
export const API_HOST = "https://dev.api.nickeldxm.com";
export const API_NAME = "dev-nickel-api";
export const DWP_NAME = 'dev-dwp';
export const DWP_HOST = "https://dev.dwp.nickeldxm.com";
export const SOCKET_HOST = "wss://dev.socket.nickeldxm.com";

export const PERSONA_ENV = "sandbox";
export const PERSONA_TEMPLATE_ID = "tmpl_5d55bb1T19iu1UP21QPbH3Ed";

export const COLORS = {
  PRIMARY: '#2487ff',
  ERROR: '#e0614a',
};

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const FILE_CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

export const STRIPE_CLIENT_ID = "ca_8dgB21hlEyxr1J2UK0WRDnZTycIZSs7K";

export const CI_TESTING = true;
