import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { FiCalendar, FiDollarSign } from "react-icons/fi";
import { MdCheck, MdClose } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { jsToSqlDate } from "../../../utils/jsToSqlDate";
import CampaignsStore from "../../../stores/CampaignsStore";
import TenantStore from "../../../stores/TenantStore";
import TeamsStore from "../../../stores/TeamsStore";
import Page from "../../-common/Page";
import Button from "../../-common/Button";
import Input from "../../-common/Input";
import Textarea from "../../-common/Textarea";
import RadioGroup from "../../-common/RadioGroup";
import Select from "../../-common/Select2";
import CurrencyInput from "../../-common/CurrencyInput";
import "./General.scss";
import Datepicker from "../../-common/Datepicker";

const General = ({ campaignId }) => {
  CampaignsStore.setSelectedCampaign(campaignId);
  const [originalCampaign, setOriginalCampaign] = useState({});
  const [campaign, setCampaign] = useState({});
  const [saving, setSaving] = useState(false);
  const [isMulti, setIsMulti] = useState(true);
  const [localTeams, setLocalTeams] = useState([]);
  const [originalTeams, setOriginalTeams] = useState([]);

  const currentCampaign = CampaignsStore.currentCampaign;
  const { title, description, startDate, endDate, revenueGoal, teams, pointMultiplier, quid } = campaign || {};

  const teamOptions = TeamsStore.teams?.map(team => {
    return { label: team.name, value: team.teamId };
  });

  const quidOptions = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" }
  ];

  const selectedQuid = (quidOptions?.filter(option => option?.value === quid)[0] || {}).value;

  useEffect(() => {
    let campaign = currentCampaign;
    if (campaign) {
      campaign = toJS(campaign, { recurseEverything: true });
      setOriginalCampaign(campaign);
      setCampaign(campaign);
    }
  }, [currentCampaign, setOriginalCampaign, setCampaign]);

  useEffect(() => {
    if (campaign?.teams) {
      const selectedTeamOptions = teams?.map(team => {
        return { label: team.name, value: team.teamId };
      });
      setLocalTeams(selectedTeamOptions);
      setOriginalTeams(selectedTeamOptions);
    }
  }, [teams, campaign]);

  const updateLocalTeams = teams => {
    const allOption = teams?.length && teams?.find(t => t.value === "All");
    if (allOption) {
      teams = allOption;
      setIsMulti(false);
    } else {
      setIsMulti(true);
    }
    setLocalTeams(teams);
  };

  const augmentedOptions = isMulti ? [{ label: "All", value: "All" }].concat(teamOptions) : teamOptions;

  const pointMultiplierText = `Every $1 donated = ${pointMultiplier} priority point${
    +pointMultiplier === 1 ? "" : "s"
  }`;

  const save = useCallback(async () => {
    setSaving(true);
    let selectedTeamIds;
    if (localTeams?.length) {
      selectedTeamIds = localTeams?.map(team => team.value);
    }

    const updatedCampaign = await CampaignsStore.updateCampaign({
      ...campaign,
      teamIds: selectedTeamIds
    });
    setOriginalCampaign(updatedCampaign);
    setSaving(false);
  }, [setSaving, campaign, setOriginalCampaign, localTeams]);

  const cancel = useCallback(() => {
    setCampaign(originalCampaign);
  }, [originalCampaign, setCampaign]);

  const { hostingURL } = TenantStore?.tenant?.config;
  const campaignLink = `${hostingURL}?campaignId=${campaign.campaignId}`;

  const checkSaveEnabled = ({ originalCampaign, campaign, localTeams }) => {
    if (campaign.title !== originalCampaign.title) return true;
    if (campaign.description !== originalCampaign.description) {
      return true;
    }
    if (campaign.revenueGoal !== originalCampaign.revenueGoal) {
      return true;
    }
    if (campaign.startDate !== originalCampaign.startDate) {
      return true;
    }
    if (campaign.endDate !== originalCampaign.endDate) {
      return true;
    }
    if (campaign.pointMultiplier !== originalCampaign.pointMultiplier) {
      return true;
    }
    if (campaign.quid !== originalCampaign.quid) {
      return true;
    }
    if (localTeams?.length !== originalTeams?.length && localTeams?.length) {
      return true;
    }

    // eslint-disable-next-line
    for (let { value: teamId } of localTeams || []) {
      if (!originalTeams.map(t => t.value).includes(teamId)) {
        return true;
      }
    }
    return false;
  };

  const saveEnabled = checkSaveEnabled({
    originalCampaign,
    campaign,
    localTeams
  });

  const updateTitle = useCallback(
    value => {
      setCampaign({ ...campaign, title: value });
    },
    [campaign, setCampaign]
  );

  const updateRevenueGoal = useCallback(
    value => {
      setCampaign({ ...campaign, revenueGoal: value });
    },
    [campaign, setCampaign]
  );

  const updateDescription = useCallback(
    value => {
      setCampaign({ ...campaign, description: value });
    },
    [campaign, setCampaign]
  );

  const updatePointMultiplier = useCallback(
    value => {
      setCampaign({ ...campaign, pointMultiplier: value });
    },
    [campaign, setCampaign]
  );

  const updateQuid = useCallback(
    value => {
      setCampaign({ ...campaign, quid: value });
    },
    [campaign, setCampaign]
  );

  const updateStartDate = useCallback(
    value => {
      setCampaign({ ...campaign, startDate: jsToSqlDate(value) });
    },
    [campaign, setCampaign]
  );

  const updateEndDate = useCallback(
    value => {
      setCampaign({ ...campaign, endDate: jsToSqlDate(value) });
    },
    [campaign, setCampaign]
  );

  const conditionalButtons = saveEnabled
    ? [
        <Button text="Cancel" icon={MdClose} theme="light" onClick={cancel} disabled={saving} key="cancel" />,
        <Button
          text={saving ? "Saving..." : "Save"}
          theme={saving ? "light" : null}
          icon={MdCheck}
          onClick={save}
          disabled={saving}
          key="save"
        />
      ]
    : [
        <Button
          text="Preview"
          onClick={() => window.open(`${campaignLink}&preview=true`, "_blank")}
          icon={IoMdEye}
          key="preview"
        />
      ];

  return (
    <Page titleOpts={{ title: "General" }} buttons={conditionalButtons}>
      <div className="manage-title">
        <div className="inputs-container">
          <Input value={title} onChange={updateTitle} label="Campaign Title" maxLength={60} countCharacters={true} />
          <Select
            className="team-select"
            isMulti={isMulti}
            options={augmentedOptions}
            value={localTeams}
            onChange={updateLocalTeams}
            label="Select your team"
            autoGrow
          />
          <CurrencyInput
            value={revenueGoal || 0}
            onChange={updateRevenueGoal}
            label="Donation Goal"
            icon={FiDollarSign}
          />
          <Textarea value={description} onChange={updateDescription} label="Description" />
          <div className="double-input-container">
            <Datepicker value={startDate} onChange={updateStartDate} label="Start date" icon={FiCalendar} />
            <Datepicker value={endDate} onChange={updateEndDate} label="End date" icon={FiCalendar} />
          </div>
          <div className="point-multiplier-container">
            <Input
              value={pointMultiplier}
              onChange={updatePointMultiplier}
              label="How many priority points?"
              icon={AiOutlineClose}
              inputContainerStyles={{ width: "20%" }}
              maxLength={3}
            />
            <div className="point-multiplier-explanation">{pointMultiplierText}</div>
          </div>
          <RadioGroup
            label="Quid pro quo for this campaign?"
            id="quid-type"
            options={quidOptions}
            selected={selectedQuid}
            onChange={updateQuid}
          />
        </div>
      </div>
    </Page>
  );
};

export default observer(General);
