import React from 'react';
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryStack,
  VictoryLegend,
} from 'victory';
import useDimensions from 'react-cool-dimensions';
import classNames from 'classnames';

import SummaryNumbers from '@/common/SummaryNumbers';
import ContributionsTable from '@/common/ContributionsTable';
import { getRolling12MonthsLabels } from '@/utils/getRolling12MonthsLabels';

import './Contributions.scss';

const chartMonthLabels = getRolling12MonthsLabels();

const contributionChartColors = [
  {
    name: 'Donation via external',
    color: '#70c9cc',
  },
  {
    name: 'Donation via New Membership Signup',
    color: '#0a3a57',
  },
  {
    name: 'Donation via mobile app',
    color: '#f0c60a',
  },
  {
    name: 'Donation via public campaign page',
    color: '#3d345e',
  },
  {
    name: 'Donation via admin entry',
    color: '#28996d',
  },
];

const Contributions = ({
  YTDContributions,
  lifetimeContributions,
  totalYearsOfMembership,
  currentRank,
  totalRank,
  totalPriorityPoints,
  transactions,
  chartTransactions,
  donorTransactionsCSV,
}) => {
  const {
    width: renderWidth,
    ref: containerRef,
  } = useDimensions();

  const shouldUseColumnView = renderWidth < 900;
  const legendData = [
    { name: 'Annual' },
    { name: 'Football' },
    { name: 'Basketball' },
  ];

  const usedChartColorsArray = chartTransactions.map(transactionsGroup => contributionChartColors.find(colorAssignment => colorAssignment.name === transactionsGroup.name)?.color || '#000');

  return (
    <>
      <SummaryNumbers
        YTDContributions={YTDContributions}
        lifetimeContributions={lifetimeContributions}
        totalYearsOfMembership={totalYearsOfMembership}
        currentRank={currentRank}
        totalRank={totalRank}
        totalPriorityPoints={totalPriorityPoints}
      />
      <section className="contributions-content">
        <div className="contributions-wrapper">
          <div className="chart-title">Contributions Last 12 Months</div>
          <div
            className={classNames('chart-wrapper', { 'chart-wrapper--column': shouldUseColumnView })}
            ref={containerRef}
          >
            <div className="contributions-chart">
              <VictoryChart
                domainPadding={{
                  x: renderWidth / 30,
                  y: renderWidth / 20,
                }}
                padding={70}
                theme={VictoryTheme.material}
                width={renderWidth}
                height={renderWidth / 2.2}
              >
                <VictoryAxis
                  tickValues={chartMonthLabels.numeral}
                  tickFormat={chartMonthLabels.lexical}
                  style={{
                    axis: { stroke: 'none' },
                    ticks: { stroke: 'none' },
                  }}
                />
                <VictoryAxis
                  dependentAxis
                  tickFormat={x => `$${x}`}
                  style={{
                    axis: { stroke: 'none' },
                    ticks: { stroke: 'none' },
                  }}
                />
                <VictoryStack>
                  {chartTransactions.map((transactionsGroup, index) => (
                    <VictoryBar
                      data={transactionsGroup.data}
                      x="month"
                      y="donations"
                      style={{ data: { fill: usedChartColorsArray[index] } }}
                      barWidth={renderWidth / 15}
                    />
                  ))}
                </VictoryStack>
              </VictoryChart>
            </div>
            <div className="contributions-legend">
              <VictoryLegend
                width={shouldUseColumnView ? legendData.length * 160 : 275}
                height={shouldUseColumnView ? 70 : legendData.length * 30}
                colorScale={usedChartColorsArray}
                data={chartTransactions.map(transactionsGroup => (
                  { name: transactionsGroup.name }
                ))}
                orientation={shouldUseColumnView ? 'horizontal' : 'vertical'}
                symbolSpacer={10}
                itemsPerRow={shouldUseColumnView ? 2 : undefined}
              />
            </div>
          </div>
        </div>
        <div className="contributions-table">
          <ContributionsTable
            transactions={transactions}
            donorTransactionsCSV={donorTransactionsCSV}
          />
        </div>
      </section>
    </>
  );
};

export default Contributions;
