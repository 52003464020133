import { observable, computed, action, autorun } from 'mobx';
import { parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import {
  getVideoId,
  isVideoLink,
  request,
} from '@/utils';
import { sortValues } from '@/constants/sortValues';
import uploadFile from '../services/FileUploadService';
import AuthStore from './AuthStore';
import AppStateStore from './AppStateStore';

function getFirstPublishDate(b) {
  return b?.schedules?.reduce((acc, next) => {
    if (!acc) return next?.publishDate;
    return next?.publishDate < acc ? next.publishDate : acc;
  }, null);
}

// const searchBulletins = searchText => b => {
//   const search = searchText.toLowerCase();
//   const matchesTitle = b?.subject.toLowerCase().includes(search);
//   const matchesDescription = b?.message?.toLowerCase().includes(search);
//   return matchesTitle || matchesDescription;
// };

export const filterBulletinsOptions = [{ value: 'All', label: 'All' }];

// const filterBulletins = filter => b => {
//   if (filter.value === "All") return true;
//   return b?.category === filter.value;
// };

export const sortBulletinsOptions = [
  { label: sortValues.ALPH_ASC, value: sortValues.ALPH_ASC },
  { label: sortValues.ALPH_DESC, value: sortValues.ALPH_DESC },
  { label: sortValues.NEWEST, value: sortValues.NEWEST },
  { label: sortValues.OLDEST, value: sortValues.OLDEST },
];

// const sortBulletins = sort => (b1, b2) => {
//   const title1 = b1?.subject.toUpperCase();
//   const title2 = b2?.subject.toUpperCase();
//   if (sort.value === "A to Z") {
//     return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
//   } else if (sort.value === "Z to A") {
//     return title1 > title2 ? -1 : title1 < title2 ? 1 : 0;
//   } else if (sort.value === "Newest") {
//     return new Date(b2?.createdDate).valueOf() - new Date(b1?.createdDate).valueOf();
//   } else if (sort.value === "Oldest") {
//     return new Date(b2?.createdDate).valueOf() - new Date(b1?.createdDate).valueOf();
//   }
// };
class BulletinsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchBulletins();
      } else {
        this.clear();
      }
    });
  }

  @observable rawBulletins = [];

  @computed get bulletins() {
    return this.rawBulletins
      ?.map(b => {
        const schedules = b?.schedules?.map(c => ({ ...c, publishDate: parseISO(c?.publishDate) }));
        const firstPublishDate = getFirstPublishDate({ ...b, schedules });
        const publishedSteps = schedules?.filter(s => s?.publishDate < new Date())?.length;
        let status = 'Pending';

        if (publishedSteps > 0) {
          status = 'In Progress';
        }

        if (schedules?.length === publishedSteps) {
          status = 'Complete';
        }

        return { ...b, schedules, status, firstPublishDate };
      })
      ?.sort((a, b) => {
        if (a.firstPublishDate < b.firstPublishDate) return 1;
        else if (b.firstPublishDate < a.firstPublishDate) return -1;

        if (a.subject < b.subject) return -1;
        else if (b.subject < a.subject) return 1;

        if (a.message < b.message) return -1;
        else if (b.message < a.message) return 1;

        return 0;
      });
  }

  @action async fetchBulletins() {
    try {
      const bulletins = await request.get(`/v1/bulletins`);
      this.rawBulletins = bulletins;
      const withThumbnails = await Promise.all(
        this.rawBulletins.map(this.addVideoThumbnailToBulletin.bind(this)),
      );
      this.rawBulletins = withThumbnails;
      return bulletins;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addBulletin(params) {
    // params: { subject, message, file, targetType, targetId }
    // bulletinObject: { subject, message, media, type, targetType, targetId }
    try {
      AppStateStore.setLoading(true);
      const { file, video, ...bulletinObject } = params || {};
      if (file) {
        const url = await uploadFile(file, 'bulletin');
        bulletinObject.media = url;
      } else if (video) {
        bulletinObject.media = video;
      }
      const newBulletin = await request.post(`/v1/bulletins`, {
        body: bulletinObject,
      });
      const withThumbnail = await this.addVideoThumbnailToBulletin(newBulletin);
      this.rawBulletins = this.rawBulletins.concat(withThumbnail);
      AppStateStore.setLoading(false);
      toast('Bulletin created.');
      return newBulletin;
    } catch (err) {
      AppStateStore.setLoading(false);
      console.warn(err);
      toast('Error creating bulletin.');
    }
  }

  @action async updateBulletin(bulletinId, params) {
    // params: { subject, message, file, targetType, targetId }
    // bulletinObject: { subject, message, media, type, targetType, targetId }
    try {
      const { file, video, subject, message, type, targetType, targetId } = params || {};
      let media;
      if (file) {
        const url = await uploadFile(file, 'bulletin');
        media = url;
      } else if (video) {
        media = video;
      }
      let newBulletin = await request.put(`/v1/bulletins/${bulletinId}`, {
        body: { subject, message, media, type, targetType, targetId },
      });
      if (video) newBulletin = await this.addVideoThumbnailToBulletin(newBulletin);
      this.rawBulletins = this.rawBulletins.map(b => {
        if (b?.bulletinId === bulletinId) return newBulletin;
        return b;
      });
      toast('Bulletin updated.');
      return newBulletin;
    } catch (err) {
      AppStateStore.setLoading(false);
      console.warn(err);
      toast('Error updating bulletin.');
    }
  }

  @action async updateScheduleForBulletin(bulletinId, scheduleItems) {
    if (!scheduleItems?.length) return;

    AppStateStore.setLoading(true);
    try {
      const fullScheduleItems = scheduleItems.map(si => ({
        targetId: bulletinId,
        targetType: 'bulletin',
        publishDate: Number(si?.publishDate),
        referenceId: si?.referenceId,
        referenceType: 'membership',
      }));
      const newScheduleItems = await request.post('/v1/schedules', { body: fullScheduleItems });
      this.rawBulletins = this.rawBulletins?.map(b => {
        if (b?.bulletinId === bulletinId) {
          return { ...b, schedules: newScheduleItems };
        } else {
          return b;
        }
      });
      AppStateStore.setLoading(false);
      toast('Bulletin schedule updated.');
      return newScheduleItems;
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error updating schedule for bulletin.');
    }
  }

  @action async deleteScheduleStepsForBulletin(bulletinId, stepIdsToRemove) {
    AppStateStore.setLoading(true);
    try {
      await request.delete(`/v1/schedules?scheduleIds=${stepIdsToRemove?.join(',')}`);
      this.rawBulletins = this.rawBulletins?.map(b => {
        if (b?.bulletinId === bulletinId) {
          return {
            ...b,
            schedules: b?.schedules?.filter(s => !stepIdsToRemove?.includes(s?.scheduleId)),
          };
        } else {
          return b;
        }
      });
      AppStateStore.setLoading(false);
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error updating schedule for bulletin.');
    }
  }

  @action async deleteBulletin(bulletinId) {
    AppStateStore.setLoading(true);
    try {
      await request.delete(`/v1/bulletins/${bulletinId}`);
      this.rawBulletins = this.rawBulletins.filter(b => b?.bulletinId !== bulletinId);
      toast('Bulletin deleted.');
    } catch (err) {
      console.warn(err);
      toast('Error deleting bulletin.');
    }
    AppStateStore.setLoading(false);
  }

  @action async addVideoThumbnailToBulletin(bulletin) {
    const { media, thumbnail: existingThumbnail } = bulletin;
    const videoLinkType = isVideoLink(media);
    if (videoLinkType && !existingThumbnail) {
      let thumbnail;
      if (videoLinkType === 'internal') {
        thumbnail = media.replace(/mp4$/g, 'png');
      } else if (media.includes('vimeo.com')) {
        const response = await fetch(`https://vimeo.com/api/oembed.json?url=${media}`);
        const { thumbnail_url } = await response.json();
        thumbnail = thumbnail_url;
      } else {
        const { id: videoId } = getVideoId(media);
        thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`;
      }
      bulletin.thumbnail = thumbnail;
    }
    return bulletin;
  }

  // CLEANUP
  @action clear() {
    this.rawBulletins = [];
  }
}

export default new BulletinsStore();
