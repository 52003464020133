import {
  observable, action, decorate,
} from 'mobx';
import Amplify, { Auth } from 'aws-amplify';
import { navigate } from '@reach/router';
import request from '@/utils/request';
import getSubdomain from '@/utils/getSubdomain';

function isDev() {
  const isLocal = getSubdomain() === 'localhost';
  const isDevDomain = window.location.href.replace(/https?:\/\//, '').split('.')[1] === 'dev';

  return isLocal || isDevDomain;
}

function getConfigKey() {
  return `nickel:${getSubdomain()}:config`;
}

// function getConfig() {
//   const encodedConfig = window.localStorage.getItem(getConfigKey());
//   if (encodedConfig) return JSON.parse(atob(encodedConfig));
// }

function cacheConfig(config) {
  const encodedConfig = btoa(JSON.stringify(config));

  window.localStorage.setItem(getConfigKey(), encodedConfig);
}

function clearCachedConfig() {
  window.localStorage.removeItem(getConfigKey());
}

function configureAmplify(config) {
  try {
    Amplify.configure(config);
  } catch (err) {
    if (isDev()) console.warn(err);
  }
}

class InstanceConfigStore {
  constructor() {
    this.fetchInstanceConfig();
  }

  loading = true;

  authConfigured = false;

  disabled;

  authConfig;

  tenant;

  passwordPolicy;

  isDev = isDev();

  subdomain = getSubdomain();

  async fetchInstanceConfig() {
    const fetchedConfig = await request.unauthenticated.get('/v1/auth/params');

    const config = fetchedConfig;

    cacheConfig(fetchedConfig);

    this.loading = false;

    if (config.disabled) {
      await Auth.signOut();
      navigate('/auth');
      this.disabled = true;

      return;
    }

    const {
      tenant, passwordPolicy, ...authConfig
    } = config;

    this.authConfig = authConfig;
    this.tenant = config.tenant;
    this.passwordPolicy = passwordPolicy;

    configureAmplify(authConfig);
    this.authConfigured = true;
    this.disabled = false;
  }

  clear() {
    this.authConfig = null;
    this.tenant = null;
    this.passwordPolicy = null;

    clearCachedConfig();
  }
}

const DecoratedInstanceConfigStore = decorate(InstanceConfigStore, {
  loading: observable,
  authConfigured: observable,

  disabled: observable,

  authConfig: observable,
  tenant: observable,
  passwordPolicy: observable,

  isDev: observable,
  subdomain: observable,

  fetchInstanceConfig: action,

  clear: action,
});

export default new DecoratedInstanceConfigStore();
