import { observable, computed, action, autorun } from 'mobx';
import { toast } from 'react-toastify';
import { request } from '@/utils';
import { sortValues } from '@/constants/sortValues';
import uploadFile from '../services/FileUploadService';
import AuthStore from './AuthStore';
import AppStateStore from './AppStateStore';

const searchRewards = searchText => r => {
  const search = searchText.toLowerCase();
  const matchesTitle = r.title.toLowerCase().includes(search);
  const matchesDescription = r.description.toLowerCase().includes(search);
  const matchesAvailable = String(r.availableQuantity).toLowerCase().includes(search);
  const matchesReserved = String(r.reservedQuantity).toLowerCase().includes(search);
  const matchesClaimed = String(r.claimedQuantity).toLowerCase().includes(search);
  return (
    matchesTitle || matchesDescription || matchesAvailable || matchesReserved || matchesClaimed
  );
};

export const filterOptions = [
  { value: 'All', label: 'All' },
  { value: 'physical', label: 'Physical Goods' },
  { value: 'experience', label: 'Experiences' },
  { value: 'sponsorship', label: 'Sponsorship or Discount Codes' },
  { value: 'contest', label: 'Contests' },
];

const filterRewards = filter => r => {
  if (filter.value === 'All') return true;
  return r.category === filter.value;
};

export const sortOptions = [
  { label: sortValues.ALPH_ASC, value: sortValues.ALPH_ASC },
  { label: sortValues.ALPH_DESC, value: sortValues.ALPH_DESC },
  { label: sortValues.MOST_AVAILABLE, value: sortValues.MOST_AVAILABLE },
];

const sortRewards = sort => (r1, r2) => {
  const title1 = r1.title.toUpperCase();
  const title2 = r2.title.toUpperCase();
  if (sort.value === sortValues.ALPH_ASC) {
    return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
  } else if (sort.value === sortValues.ALPH_DESC) {
    return title1 > title2 ? -1 : title1 < title2 ? 1 : 0;
  } else if (sort.value === sortValues.MOST_AVAILABLE) {
    const r1Available = r1.initialQuantity - r1.reservedQuantity;
    const r2Available = r2.initialQuantity - r2.reservedQuantity;
    if (r1Available === r2Available) {
      return title1 < title2 ? -1 : title1 > title2 ? 1 : 0;
    } else {
      return r2Available - r1Available;
    }
  }
};

class RewardsStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchRewards();
      } else {
        this.clear();
      }
    });
  }

  @observable rawRewards = [];

  @computed get allRewards() {
    return this.rawRewards;
  }

  @computed get allRewardsByCategory() {
    return (
      this.allRewards?.reduce((acc, next) => {
        if (acc[next.category]) acc[next.category].push(next);
        else acc[next.category] = [next];
        return acc;
      }, {}) || {}
    );
  }

  @computed get rewards() {
    return (
      this.allRewards
        ?.filter(filterRewards(this.filter))
        ?.filter(searchRewards(this.search))
        ?.sort(sortRewards(this.sort)) || []
    );
  }

  @computed get rewardOptions() {
    const uniqueOptions = Array.from(new Set(...[(this.allRewards || []).map(o => o.category)]));
    const standardOptions = [
      { label: 'No Reward', value: 'none' },
      { label: 'Physical Good', value: 'physical' },
      { label: 'Experience', value: 'experience' },
      { label: 'Sponsorhip or Discount Code', value: 'sponsorship' },
      { label: 'Contest', value: 'contest' },
    ];
    const currentOptions = standardOptions.filter(option => uniqueOptions.includes(option.value));

    return [standardOptions[0], ...currentOptions];
  }

  @computed get rewardsByCategory() {
    return this.rewards.reduce((acc, next) => {
      if (acc[next.category]) acc[next.category].push(next);
      else acc[next.category] = [next];
      return acc;
    }, {});
  }

  @observable rewardCategory;

  @action setRewardCategory(category) {
    this.rewardCategory = category;
  }

  @computed get rewardsForCategory() {
    if (!this.rewardCategory) return this.allRewards;
    return this.allRewards.filter(r => r.category === this.rewardCategory);
  }

  @action async fetchRewards() {
    try {
      const rewards = await request.get('/v1/rewards');
      this.rawRewards = rewards;
      return rewards;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async uploadFile(file) {
    try {
      const url = await uploadFile(file, 'rewards');
      return url;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async addReward(rewardsObject, file) {
    AppStateStore.setLoading(true);
    try {
      if (file) {
        let url = await this.uploadFile(file);
        url = url.split('?')[0];
        const rewardsObj = { ...rewardsObject, media: url };
        const newRewards = await request.post(`/v1/rewards?userId=${AuthStore?.userId}`, {
          body: rewardsObj,
        });
        this.rawRewards = this.rawRewards.concat(newRewards);
        AppStateStore.setLoading(false);
        toast('Reward created!', { autoClose: 3000 });
        return true;
      } else {
        const newRewards = await request.post(`/v1/rewards?userId=${AuthStore?.userId}`, {
          body: rewardsObject,
        });
        this.rawRewards = this.rawRewards.concat(newRewards);
        AppStateStore.setLoading(false);
        toast('Reward created!', { autoClose: 3000 });
        return true;
      }
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error creating reward.');
      return false;
    }
  }

  @action updateRewardInPlace(updatedReward) {
    this.rawRewards = this.rawRewards.map(r => {
      if (r.rewardId === updatedReward.rewardId) return updatedReward;
      return r;
    });
  }

  @action async removeReward(rewardId) {
    AppStateStore.setLoading(true);
    try {
      await request.delete(`/v1/rewards/${rewardId}`);
      this.rawRewards = this.rawRewards.filter(r => r.rewardId !== rewardId);
      AppStateStore.setLoading(false);
      toast('Reward deleted.', { autoClose: 3000 });
      return true;
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error deleting reward.');
      return false;
    }
  }

  // STOCK
  @action async addRewardStock(rewardId, amount) {
    AppStateStore.setLoading(true);
    try {
      const updatedReward = await request.post(
        `/v1/rewards/${rewardId}/stock?userId=${AuthStore?.userId}`,
        {
          body: { amount: +amount },
        },
      );
      this.updateRewardInPlace(updatedReward);
      AppStateStore.setLoading(false);
      toast('Reward stock added!', { autoClose: 3000 });
      return true;
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error adding stock to reward.');
      return false;
    }
  }

  @action async removeRewardStock(rewardId, amount) {
    AppStateStore.setLoading(true);
    try {
      const updatedReward = await request.post(
        `/v1/rewards/${rewardId}/stock?userId=${AuthStore?.userId}`,
        {
          body: { amount: -+amount },
        },
      );
      this.updateRewardInPlace(updatedReward);
      AppStateStore.setLoading(false);
      toast('Reward stock removed!', { autoClose: 3000 });
      return true;
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error removing stock from reward.');
      return false;
    }
  }

  // MODAL
  @observable showNewRewardModal = false;

  @action openNewRewardModal = () => (this.showNewRewardModal = true);

  @action closeNewRewardModal = () => (this.showNewRewardModal = false);

  // SEARCH
  @observable search = '';

  @action setSearch = search => (this.search = search);

  // FILTER AND SORT
  @observable filter = filterOptions[0];

  @action setFilter(filter) {
    this.filter = filter;
  }

  @observable sort = sortOptions[0];

  @action setSort(sort) {
    this.sort = sort;
  }

  // CLEANUP
  @action clear() {
    this.rawRewards = [];
  }
}

export default new RewardsStore();
