import * as Yup from 'yup';

export const teamPerksFormSchema = Yup.object().shape({
  teamId: Yup.string()
    .required('Required'),
  maxTickets: Yup.number(),
  section: Yup.string(),
  priority: Yup.string(),
  parking: Yup.string(),
  extras: Yup.array().of(Yup.string()),
});
