import * as Yup from 'yup';

export default Yup.object().shape({
  emails: Yup.array().of(Yup.string().email('Invalid email address')),
  phoneNumbers: Yup.array().of(Yup.string()),
  city: Yup.string(),
  zip: Yup.string(),
  streetLine1: Yup.string(),
  streetLine2: Yup.string(),
  state: Yup.string(),
  primaryPhoneNumber: Yup.string(),
  primaryEmail: Yup.string(),
});
