import React, { useState } from 'react';
import { observer } from 'mobx-react';

import sportIcons from '@/common/sportIcons';
import Select from '@/common/Select2';

import './BenefitsList.scss';

const BenefitsList = ({
  amountFromNextLevel, shownMembershipId, nextLevelName, memberships, hideMembershipSelector,
}) => {
  const [
    selectedMembershipId,
    setSelectedMembershipId,
  ] = useState(shownMembershipId);
  const membershipsOptions = memberships.map(({
    membershipId, name, minDonationThreshold, maxDonationThreshold,
  }) => ({
    value: membershipId,
    label: `${name} ($${minDonationThreshold} - $${maxDonationThreshold})`,
  }));

  const selectedMembership = memberships
    .find(({ membershipId }) => membershipId === selectedMembershipId);

  return (
    <section className="benefits">
      <section className="benefits-title-section">
        <h2 className="benefits-title">Member Benefits</h2>
        {!hideMembershipSelector && (
          <div className="benefits-select">
            <Select
              options={membershipsOptions}
              value={
                membershipsOptions?.find(
                  option => option.value === selectedMembershipId
                ) || memberships[0].membershipId
              }
              onChange={option => setSelectedMembershipId(option.value)}
            />
          </div>
        )}
        {!!nextLevelName && !!amountFromNextLevel && (
          <span className="benefits-next-level">{`Currently $${amountFromNextLevel} from ${nextLevelName}`}</span>
        )}
      </section>
      <section className="benefit-cards">
        {selectedMembership.benefits.map(({
          id, sport, maxTickets, section, priority, parking, extras, teamIconName,
        }) => {
          const Icon = teamIconName ? sportIcons[teamIconName] : () => null;

          return (
            <article
              className="benefit-card"
              key={id}
            >
              <figure className="team-icon">
                <Icon
                  size={20}
                  fill="#fff"
                />
              </figure>
              <span className="sport-title">{sport}</span>
              <span className="tickets-count">{maxTickets}</span>
              <span className="tickets-count-label">Max Tickets</span>
              <div className="benefit-details">
                <div className="benefit-box">
                  <span className="benefit-label">Section</span>
                  <span className="benefit-value">{section}</span>
                </div>
                <div className="benefit-box">
                  <span className="benefit-label">Priority</span>
                  <span className="benefit-value">{priority}</span>
                </div>
                <div className="benefit-box">
                  <span className="benefit-label">Parking</span>
                  <span className="benefit-value">{parking}</span>
                </div>
              </div>
              <div className="benefit-extras">
                <span className="benefit-label">Extras</span>
                <ul className="benefit-extras-list">
                  {extras.map(element => (
                    <li
                      key={
                      element.split(' ')
                        .join('')
                        .toLowerCase()
                      }
                    >
                      <span className="benefit-value">{`- ${element}`}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </article>
          );
        })}
      </section>
    </section>
  );
};

export default observer(BenefitsList);
