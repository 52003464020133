import { observable, action, autorun, computed } from 'mobx';
import { toast } from 'react-toastify';
import { request } from '../utils';
import { NICKEL_HOSTED_URL } from '../constants/main';
import AuthStore from './AuthStore';
import AppStateStore from './AppStateStore';

const updateThemeStyle = (styleVar, value) => {
  document.getElementsByTagName('html')[0].style.setProperty(styleVar, value);
};

class TenantStore {
  constructor() {
    autorun(() => {
      if (AuthStore.AdminAPIReady) {
        this.fetchTenant();
      } else if (AuthStore.DonorAPIReady) {
        this.fetchDonorTenant();
      } else {
        this.clear();
      }
    });
  }

  // TEAMS
  @observable rawTenant = {};

  @computed get tenant() {
    const { config } = this.rawTenant;
    return {
      ...this.rawTenant,
      config: { ...config, hostingURL: config?.hostingURL ? config?.hostingURL : NICKEL_HOSTED_URL },
    };
  }

  @computed get clubName() {
    return this.rawTenant?.club?.name || '';
  }

  @action setTenant(tenant) {
    let {
      dxmPrimaryColor,
      dxmSecondaryColor,
      primaryColor,
      secondaryColor,
      appPrimaryColor,
    } = tenant?.theme || {};
    primaryColor = dxmPrimaryColor || primaryColor;
    secondaryColor = dxmSecondaryColor || secondaryColor;

    if (primaryColor) updateThemeStyle('--primary-color', primaryColor);
    if (secondaryColor) updateThemeStyle('--secondary-color', secondaryColor);
    if (appPrimaryColor) updateThemeStyle('--app-primary-color', appPrimaryColor);

    this.rawTenant = tenant;
  }

  @action async fetchTenant() {
    try {
      const tenant = await request.get('/v1/tenant');
      this.setTenant(tenant);
      return tenant;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async fetchDonorTenant() {
    try {
      const tenant = await request.dwpAuthenticated.get('/v1/tenant');

      this.setTenant(tenant);

      return tenant;
    } catch (err) {
      console.warn(err);

      throw err;
    }
  }

  @action async updateTenant(updates) {
    try {
      const tenant = await request.put('/v1/tenant', {
        body: updates,
      });
      this.setTenant(tenant);
      return tenant;
    } catch (err) {
      console.warn(err);
    }
  }

  @action async registerTenantWithZift(finalRegistrationParams) {
    AppStateStore.setLoading(true);
    try {
      const tenant = await request.post('/v1/tenant/register', {
        body: finalRegistrationParams,
      });
      this.setTenant(tenant);
      AppStateStore.setLoading(false);
      toast('Application received. Approval pending.');
      return tenant;
    } catch (err) {
      AppStateStore.setLoading(false);
      toast('Error registering for payment processing.');
    }
  }

  @action async uploadImages({
    logo,
    emblem,
    appEmblem,
    appHeaderLogo,
    ticketsInterstitial,
    ticketRenewalPopup,
  }) {
    try {
      let logoUrl, emblemUrl, appEmblemUrl, appHeaderLogoUrl, ticketsInterstitialUrl, ticketRenewalPopupUrl;

      if (logo) {
        const { url, fileType } = await request.get('/v1/tenant/theme/logo/upload', {
          headers: { 'file-type': logo.type },
        });
        await request.bare.put(url, logo, {
          headers: { 'Content-Type': fileType },
        });
        logoUrl = url;
      }

      if (emblem) {
        const { url, fileType } = await request.get('/v1/tenant/theme/emblem/upload', {
          headers: { 'file-type': emblem.type },
        });
        await request.bare.put(url, emblem, {
          headers: { 'Content-Type': fileType },
        });
        emblemUrl = url;
      }

      if (appEmblem) {
        const { url, fileType } = await request.get('/v1/tenant/theme/appemblem/upload', {
          headers: { 'file-type': appEmblem.type },
        });
        await request.bare.put(url, appEmblem, {
          headers: { 'Content-Type': fileType },
        });
        appEmblemUrl = url;
      }

      if (appHeaderLogo) {
        const { url, fileType } = await request.get('/v1/tenant/theme/appheaderlogo/upload', {
          headers: { 'file-type': appHeaderLogo.type },
        });
        await request.bare.put(url, appHeaderLogo, {
          headers: { 'Content-Type': fileType },
        });
        appHeaderLogoUrl = url;
      }

      if (ticketsInterstitial) {
        const { url, fileType } = await request.get('/v1/tenant/theme/ticketsinterstitial/upload', {
          headers: { 'file-type': ticketsInterstitial.type },
        });
        await request.bare.put(url, ticketsInterstitial, {
          headers: { 'Content-Type': fileType },
        });
        ticketsInterstitialUrl = url;
      }

      if (ticketRenewalPopup) {
        const { url, fileType } = await request.get('/v1/tenant/theme/ticketrenewalpopup/upload', {
          headers: { 'file-type': ticketRenewalPopup.type },
        });
        await request.bare.put(url, ticketRenewalPopup, {
          headers: { 'Content-Type': fileType },
        });
        ticketRenewalPopupUrl = url;
      }

      await this.fetchTenant();

      return {
        logoUrl,
        emblemUrl,
        appEmblemUrl,
        appHeaderLogoUrl,
        ticketsInterstitialUrl,
        ticketRenewalPopupUrl,
      };
    } catch (err) {
      console.warn(err);
    }
  }

  @action async toggleAppFeatureEnabled(feature) {
    AppStateStore.setLoading(true);
    try {
      const enabling = !this?.tenant?.appConfig?.[feature];
      await this.updateTenant({ appConfig: { [feature]: !this?.tenant?.appConfig?.[feature] } });
      AppStateStore.setLoading(false);
      toast(`Donor app feature ${enabling ? 'enabled' : 'disabled'}.`);
    } catch (err) {
      console.warn(err);
      AppStateStore.setLoading(false);
      toast('Error enabling donor app feature.');
    }
  }

  // CLEANUP
  @action clear() {
    this.rawTenant = {};
  }
}

export default new TenantStore();
