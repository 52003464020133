import { autorun } from 'mobx';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';

import { SOCKET_HOST } from '@/constants/main';
import InstanceConfigStore from '@/stores/InstanceConfigStore';
import AuthStore from '@/stores/AuthStore';
import MessagesStore from '@/stores/MessagesStore';
import DonorMessagesStore from '@/stores/DonorMessagesStore';

let socket;

const initializeSocket = () => {
  autorun(() => {
    const { tenantId } = InstanceConfigStore?.tenant || {};
    const {
      userId, group, isAdminUser,
    } = AuthStore || {};

    if (tenantId && userId) {
      socket = new ReconnectingWebSocket(`${SOCKET_HOST}?tenantId=${tenantId}&userId=${userId}&group=${group}`);
      socket.onmessage = ({ data: socketData }) => {
        const {
          type, data,
        } = JSON.parse(socketData || '{}');

        if (type === 'message') {
          if (isAdminUser) {
            const userClubHubPath = `/club/explore/members/${data.userId}?messages`;

            MessagesStore.handleIncomingMessage(data);

            if (window.location.pathname !== userClubHubPath) {
              toast("You've got a new message in Club Hub!", { onClick: () => navigate(userClubHubPath) });
            }
          } else {
            DonorMessagesStore.handleIncomingMessage(data);
            toast("You've got a new message!");
          }
        }
      };
    } else {
      if (socket) socket.close();
      socket = null;
    }
  });
};

export default initializeSocket;
