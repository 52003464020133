import React, {
  useState,
  useEffect,
} from 'react';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { parse } from 'query-string';

import InstanceConfigStore from '@/stores/InstanceConfigStore';
import AuthStore from '@/stores/AuthStore';
import Spinner from '@/common/Spinner';
import FormikAuthInput from '@/common/AuthInput/FormikAuthInput';
import { emailValidationFormSchema } from '../schemas';
import AuthSubmitButton from '../AuthSubmitButton';
import AuthLayout from '../AuthLayout';
import './ForgotPassword.scss';

const ForgotPassword = ({ location }) => {
  const [
    emailSent,
    setEmailSent,
  ] = useState(false);
  const [
    emailParam,
    setEmailParam,
  ] = useState('');

  useEffect(() => {
    const { email } = parse(location?.search);

    if (email) {
      setEmailParam(email.replace(/\s/g, '+'));
    }

    if (location?.state.email) {
      setEmailParam(location.state.email);
    }
  }, [location]);

  const sendCodeToEmail = async values => {
    try {
      await AuthStore.sendResetPasswordEmail(values.email);
      setEmailSent(true);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleKeyup = e => {
    if (e.key === 'Enter' || e.keycode === 13) sendCodeToEmail();
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    return () => window.removeEventListener('keyup', handleKeyup);
  }, []);

  const { tenant } = InstanceConfigStore || {};

  let title;
  let subtitle;
  let inputComponent;

  if (!emailSent) {
    title = <h3 className="auth-title">Forgot your password? We&apos;ve got you covered.</h3>;
    subtitle = <p className="auth-subtitle">What email address do you use to log in?</p>;

    const buttonContents = AuthStore.loading ? <Spinner /> : 'SEND RESET LINK';

    inputComponent = (
      <div className="auth-inputs">
        <Formik
          validationSchema={emailValidationFormSchema}
          onSubmit={sendCodeToEmail}
          initialValues={{ email: emailParam }}
          enableReinitialize
        >
          <Form>
            <Field
              name="email"
              placeholder="Email Address"
              type="text"
              component={FormikAuthInput}
            />
            <AuthSubmitButton
              loading={AuthStore.loading}
              btnStyles={{ backgroundColor: tenant?.theme.appPrimaryColor }}
            >
              {buttonContents}
            </AuthSubmitButton>
          </Form>
        </Formik>
      </div>
    );
  } else {
    title = (
      <h3 className="auth-title">
        Cool - we just sent you an email with a reset link. Follow that to update your password.
      </h3>
    );
  }

  return (
    <div className="forgot-password-container">
      <AuthLayout withMenuHeader>
        <div className="auth-square">
          <img
            className="tenant-image"
            src={tenant?.theme.logo}
            alt="Tenant Logo"
          />
          {title}
          {subtitle}
          {inputComponent}
        </div>
      </AuthLayout>
    </div>
  );
};

export default observer(ForgotPassword);
