import React, { useState } from 'react';
import { observer } from 'mobx-react';

import ProfileStore from '@/stores/ProfileStore';
import ExtendedAddressesStore from '@/stores/ExtendedAddressesStore';
import DonorMessagesStore from '@/stores/DonorMessagesStore';
import TransactionsStore from '@/stores/TransactionsStore';
import TabBar from '@/common/TabBar';
import Page from '@/common/Page';
import MessagesChat from '@/common/MessagesChat';
import DonorDetailsBox from '@/common/DonorDetailsBox';
import Contributions from '@/components/@Donor/Contributions';
// import TicketsList from '@/common/TicketsList';
// import BenefitsList from '@/common/BenefitsList';
// import {
//   mockedMembershipBenefits, mockedMembershipTickets,
// } from '@/constants/mockedMembershipBenefits';

import styles from './Home.module.scss';

const donorProfileSectionsIds = {
  CONTRIBUTIONS: 'contributions',
  TICKETS: 'tickets',
  MESSAGES: 'messages',
};

const tabs = [
  {
    id: donorProfileSectionsIds.CONTRIBUTIONS,
    title: 'Contributions',
    index: 0,
  },
  // {
  //   id: donorProfileSectionsIds.TICKETS,
  //   title: 'Tickets',
  //   index: 1,
  // },
  {
    id: donorProfileSectionsIds.MESSAGES,
    title: 'Messages',
    index: 1,
  },
];

const Home = () => {
  const [
    selectedTab,
    setSelectedTab,
  ] = useState(0);

  const {
    profile: {
      profile,
      level,
      email,
      assignedDevelopmentOfficer,
      points,
      userId,
      totalDonated,
    },
  } = ProfileStore;
  const {
    sendMessage,
    messages,
    markMessagesRead,
  } = DonorMessagesStore;
  const { addresses } = ExtendedAddressesStore;
  const {
    donorTransactions,
    donorChartTransactions,
    donorTransactionsCSV,
  } = TransactionsStore;

  return (
    <Page
      titleOpts={{ title: 'Member Profile' }}
      fullWidth
    >
      <div className={styles.details}>
        <DonorDetailsBox
          media={profile?.picture}
          firstName={profile?.firstName}
          lastName={profile?.lastName}
          currentMembershipLevel={level}
          totalPriorityPoints={points}
          email={email}
          otherEmails={[
            addresses?.email1,
            addresses?.email2,
            addresses?.email3,
          ].filter(el => !!el)}
          phoneNumbers={[
            addresses?.phone1,
            addresses?.phone2,
            addresses?.phone3,
          ].filter(el => !!el)}
          DOAssignment={assignedDevelopmentOfficer ?
            `${assignedDevelopmentOfficer.profile.firstName} ${assignedDevelopmentOfficer.profile.lastName}` :
            'N/A'}
          DOAssignmentId={assignedDevelopmentOfficer?.userId}
          address={{
            addressLine1: addresses?.streetLine1,
            addressLine2: addresses?.streetLine2,
            city: addresses?.city,
            state: addresses?.state,
            zip: addresses?.zip,
          }}
          userId={userId}
        />
        <div className={styles.pageWrapper}>
          <div className={styles.tabBarWrapper}>
            <TabBar
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
          <div className={styles.tabDetail}>
            {tabs.find(
              tab => tab.index === selectedTab
            ).id === donorProfileSectionsIds.CONTRIBUTIONS && (
              <Contributions
                lifetimeContributions={totalDonated}
                totalPriorityPoints={points}
                transactions={donorTransactions}
                chartTransactions={donorChartTransactions}
                donorTransactionsCSV={donorTransactionsCSV}
                totalRank={null}
                currentRank={null}
                totalYearsOfMembership={null}
                YTDContributions={null}
              />
            )}
            {/* {tabs.find(
              tab => tab.index === selectedTab
            ).id === donorProfileSectionsIds.TICKETS && (
              <>
                <TicketsList
                  tickets={mockedMembershipTickets}
                />
                <BenefitsList
                  amountFromNextLevel={100}
                  nextLevelName="Champion"
                  shownMembershipId={2}
                  memberships={mockedMembershipBenefits}
                />
              </>
            )} */}
            {tabs.find(
              tab => tab.index === selectedTab
            ).id === donorProfileSectionsIds.MESSAGES && (
              <MessagesChat
                sendMessage={sendMessage}
                messages={messages}
                markMessagesAsRead={markMessagesRead}
                userFullName={`${profile?.firstName} ${profile?.lastName}`}
                userMedia={profile?.picture}
              />
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default observer(Home);
