import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { FiEdit } from 'react-icons/fi';
import {
  MdCheck,
  MdClose,
} from 'react-icons/md';

import AuthStore from '@/stores/AuthStore';
import UsersStore from '@/stores/UsersStore';
import ProfileStore from '@/stores/ProfileStore';
import getFileURLFromFile from '@/utils/getFileURLFromFile';
import Page from '@/common/Page';
import FileUpload from '@/common/FileUpload';
import Button from '@/common/Button';
import NewPasswordModalContainer from '../../-modals/NewPasswordModal';
import ContactDetails from '../ContactDetails';
import './Personal.scss';

const Personal = () => {
  const titleOptions = {
    title: 'Personal',
    backButton: AuthStore.isDonorUser,
  };

  const {
    profile: {
      profile,
      userId,
      email,
    },
  } = ProfileStore;

  const [
    localProfile,
    setlocalProfile,
  ] = useState();

  useEffect(() => {
    setlocalProfile(profile);
  }, [profile]);
  const { picture } = localProfile || {};

  const updateMedia = async file => {
    const newFileURL = await getFileURLFromFile(file);

    setlocalProfile({
      ...localProfile,
      picture: newFileURL,
      pictureFile: file,
    });
  };

  const [
    passwordModalOpen,
    setPasswordModalOpen,
  ] = useState(false);
  const openModal = () => {
    setPasswordModalOpen(true);
  };
  const closeModal = () => {
    setPasswordModalOpen(false);
  };

  const [
    saving,
    setSaving,
  ] = useState(false);
  const save = useCallback(async () => {
    setSaving(true);
    const {
      pictureFile,
      ...finalUser
    } = localProfile;

    await UsersStore.updateUser(userId, finalUser, pictureFile);
    setSaving(false);
  }, [
    userId,
    localProfile,
  ]);

  const cancel = useCallback(() => {
    setlocalProfile(profile);
  }, [
    setlocalProfile,
    profile,
  ]);

  const saveEnabled = !!localProfile?.pictureFile;

  const conditionalButtons = saveEnabled ?
    [
      <Button
        text="Cancel"
        icon={MdClose}
        theme="light"
        onClick={cancel}
        disabled={saving}
        key="cancel"
      />,
      <Button
        text={saving ? 'Saving...' : 'Save'}
        theme={saving ? 'light' : null}
        icon={MdCheck}
        onClick={save}
        disabled={saving}
        key="save"
      />,
    ] :
    [
      <Button
        text="Change Password"
        icon={FiEdit}
        key="preview"
        onClick={openModal}
      />,
    ];

  return (
    <Page
      titleOpts={titleOptions}
      buttons={conditionalButtons}
      fullWidth={AuthStore.isDonorUser}
    >
      <div className="personal-container">
        <div className="personal-inputs">
          <div className="picture">
            <FileUpload
              fileURL={picture}
              onUpload={updateMedia}
              circular
              accept="image/png, image/jpeg"
            />
          </div>
          <div>
            <div className="name-container">
              <span>{`${profile?.firstName} ${profile?.lastName}`}</span>
            </div>
            <span className="email">{email}</span>
          </div>
        </div>
        <ContactDetails />
      </div>
      <NewPasswordModalContainer
        user={localProfile}
        isOpen={passwordModalOpen}
        close={closeModal}
      />
    </Page>
  );
};

export default observer(Personal);
