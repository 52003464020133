import React from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { Table } from 'ka-table';
import { DataType } from 'ka-table/enums';

import usePortal from '@/hooks/usePortal';
import FullScreenModal from '@/common/FullScreenModal';
import AmountCell from '@/common/ContributionsTable/AmountCell';
import AuthStore from '@/stores/AuthStore';

import './ContributionDetailsModal.scss';

const columns = [
  {
    dataType: DataType.String,
    key: 'transactionOrigin',
    title: 'Allocation',
  },
  {
    dataType: DataType.String,
    key: 'status',
    title: 'Status',
  },
  {
    dataType: DataType.Date,
    key: 'date',
    format: d => format(d, 'M/d/yyyy'),
    title: 'Date',
  },
  {
    dataType: DataType.String,
    key: 'billingCycle',
    title: 'Billing Cycle',
  },
  {
    cell: AmountCell,
    dataType: DataType.Number,
    key: 'amountMetadata.totalDonationAmount',
    title: 'Paid',
  },
];

const ContributionDetailsModal = observer(({
  close,
  transactions,
  donorTransactionsCSV,
}) => (
  <FullScreenModal
    isOpen
    close={close}
  >
    <div className="contribution-details-modal">
      <div className="modal-contents">
        <h2 className="headline">
          <span className="title">Contribution History</span>
          {AuthStore.isDonorUser && (
            <a
              href={`data:text/csv;charset=utf-8,${encodeURI(donorTransactionsCSV)}`}
              target="_blank"
              download="donor_transactions.csv"
              rel="noopener noreferrer"
              className="button"
            >
              Download CSV
            </a>
          )}
        </h2>
        <section className="details-table">
          <Table
            columns={columns}
            data={transactions}
            rowKeyField="transactionId"
          />
        </section>
      </div>
    </div>
  </FullScreenModal>
));

const ContributionDetailsModalContainer = ({
  isOpen,
  close,
  transactions,
  donorTransactionsCSV,
}) => {
  const portalTarget = usePortal('contribution-details-modal-container');
  const contents = isOpen ?
    (
      <ContributionDetailsModal
        close={close}
        transactions={transactions}
        donorTransactionsCSV={donorTransactionsCSV}
      />
    ) :
    null;

  return createPortal(contents, portalTarget);
};

export default ContributionDetailsModalContainer;
