import React from 'react';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';
import {
  Formik,
  Form,
  Field,
  FieldArray,
  FastField,
} from 'formik';

import TeamsStore from '@/stores/TeamsStore';
import { GarbageIcon } from '@/common/Icons';
import Label from '@/common/Label';
import FormikSelectField from '@/common/FormikSelectField';
import usePortal from '@/hooks/usePortal';
import FullScreenModal from '@/common/FullScreenModal';
import Button from '@/common/Button';
import FormikAuthInput from '@/common/AuthInput/FormikAuthInput';
import { teamPerksFormSchema } from './teamPerksFormSchema';

import './TeamPerksModal.scss';

const TeamPerksModal = observer(({ close }) => {
  const save = async () => {
    close();
  };

  const teamOptions = TeamsStore.teams.map(team => ({
    label: team.name,
    value: team.teamId,
  }));

  return (
    <FullScreenModal
      isOpen
      close={close}
    >
      <div className="team-perks-modal">
        <Formik
          onSubmit={save}
          initialValues={{
            teamId: null,
            maxTickets: 0,
            section: '',
            priority: '',
            parking: '',
            extras: [],
          }}
          validationSchema={teamPerksFormSchema}
        >
          {({ values }) => (
            <Form>
              <div className="modal-contents">
                <div className="headline">Change team perks</div>
                <div>
                  <section className="inputs">
                    <Field
                      component={FormikSelectField}
                      label="Team"
                      name="teamId"
                      options={teamOptions}
                    />
                    <Label value="Max Tickets" />
                    <FastField
                      name="maxTickets"
                      placeholder="Max Tickets"
                      theme="alt"
                      component={FormikAuthInput}
                      type="number"
                      maxLength={3}
                    />
                    <Label value="Section" />
                    <FastField
                      name="section"
                      placeholder="Section"
                      type="text"
                      theme="alt"
                      component={FormikAuthInput}
                    />
                    <Label value="Priority" />
                    <FastField
                      name="priority"
                      placeholder="Priority"
                      type="text"
                      theme="alt"
                      component={FormikAuthInput}
                    />
                    <Label value="Parking" />
                    <FastField
                      name="parking"
                      placeholder="Parking"
                      type="text"
                      theme="alt"
                      component={FormikAuthInput}
                    />
                    <Label value="Extras" />
                    <FieldArray
                      name="extras"
                      render={arrayHelpers => (
                        <div>
                          {values.extras.map((extra, index) => (
                            <div
                            // eslint-disable-next-line react/no-array-index-key
                              key={`extra.${index}`}
                              className="array-field"
                            >
                              <Field
                                component={FormikAuthInput}
                                name={`extra.${index}`}
                                theme="alt"
                              />
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                className="remove-button"
                              >
                                <GarbageIcon />
                              </button>
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={() => arrayHelpers.push('')}
                            className="insert-button"
                          >
                            + Add extra perk
                          </button>
                        </div>
                      )}
                    />
                  </section>
                </div>
              </div>
              <section className="bottom-buttons">
                <Button
                  text="cancel"
                  theme="light"
                  onClick={close}
                />
                <Button
                  text="save"
                  type="submit"
                />
              </section>
            </Form>
          )}
        </Formik>
      </div>
    </FullScreenModal>
  );
});

const TeamPerksModalContainer = ({
  isOpen, close,
}) => {
  const portalTarget = usePortal('team-perks-modal-container');
  const contents = isOpen ? <TeamPerksModal close={close} /> : null;

  return createPortal(contents, portalTarget);
};

export default TeamPerksModalContainer;
