import React, { useState } from "react";
import "./TitleSlide.scss";
import Input from "../../../-common/Input";
import Select from "../../../-common/Select2";
import Textarea from "../../../-common/Textarea";

const TitleSlide = ({ title, setTitle, description, setDescription, teams, setTeams, options: teamOptions }) => {
  const [isMulti, setIsMulti] = useState(true);

  const updateLocalTeams = teams => {
    const allOption = teams.length && teams.find(t => t.value === "All");
    if (allOption) {
      teams = allOption;
      setIsMulti(false);
    } else {
      setIsMulti(true);
    }
    setTeams(teams);
  };

  const augmentedOptions = isMulti ? [{ label: "All", value: "All" }].concat(teamOptions) : teamOptions;

  return (
    <div className="slide targets-slide">
      <div className="headline">What's it about?</div>
      <div className="inputs">
        <Select
          className="select-2"
          isMulti={isMulti}
          options={augmentedOptions}
          value={teams}
          onChange={updateLocalTeams}
          label="Select your team"
          autoGrow
        />
        <Input value={title} onChange={setTitle} label="Campaign Title" countCharacters={true} maxLength={60} />
        <Textarea value={description} onChange={setDescription} label="Description" />
      </div>
    </div>
  );
};

export default TitleSlide;
