export const getRolling12MonthsLabels = () => {
  const lexicalMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const numeralMonths = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate
    .getFullYear()
    .toString();

  const thisYearLexicalMonths = lexicalMonths.slice(0, currentMonth + 1);
  const pastYearLexicalMonths = lexicalMonths.slice(thisYearLexicalMonths.length, 12);

  const formattedCurrentYearLexicalMonths = thisYearLexicalMonths.map(month => `${month}'${currentYear.substring(2)}`);
  const formattedPastYearLexicalMonths = pastYearLexicalMonths.map(month => `${month}'${currentYear.substring(2) - 1}`);

  const thisYearNumeralMonths = numeralMonths.slice(0, currentMonth + 1);
  const pastYearNumeralMonths = numeralMonths.slice(thisYearNumeralMonths.length, 12);

  const formattedCurrentYearNumeralMonths = thisYearNumeralMonths.map(month => `${currentYear}-${month}`);
  const formattedPastYearNumeralMonths = pastYearNumeralMonths.map(month => `${currentYear - 1}-${month}`);

  return {
    lexical: formattedPastYearLexicalMonths.concat(formattedCurrentYearLexicalMonths),
    numeral: formattedPastYearNumeralMonths.concat(formattedCurrentYearNumeralMonths),
  };
};
