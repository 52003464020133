import React from 'react';
import commaNumber from 'comma-number';

const AmountCell = ({ rowData }) => {
  let { totalDonationAmount } = rowData?.amountMetadata;

  totalDonationAmount = `$${commaNumber(Number(totalDonationAmount / 100 || 0)?.toFixed(2))}`;

  return (
    <div className="amount-cell">
      {totalDonationAmount}
    </div>
  );
};

export default AmountCell;
