import React from 'react';

import FieldErrorText from '@/common/FieldErrorText';
import { InputErrorIcon } from '@/common/Icons';

import './AuthInput.scss';

const FormikAuthInput = ({
  field, form, theme, ...props
}) => {
  const errorStyles = form.errors[field.name] ? { borderRight: '6px solid #E0614A' } : {};

  const errorIcon = form.errors[field.name] && (
    <div className="nickel-auth-input-error-icon">
      <InputErrorIcon />
    </div>
  );

  return (
    <div className="nickel-auth-input">
      <input
        {...field}
        {...props}
        className={theme}
        style={errorStyles}
      />
      {errorIcon}
      <FieldErrorText fieldName={field.name} />
    </div>
  );
};

export default FormikAuthInput;
