import React, {
  useState, useRef, useEffect,
} from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Picker } from 'emoji-mart';
import { FaRegSmile } from 'react-icons/fa';
import { MdSend } from 'react-icons/md';
import 'emoji-mart/css/emoji-mart.css';

import useOnClickOutside from '@/hooks/useOnClickOutside';
import useOnEnterPressed from '@/hooks/useOnEnterPressed';

import './MessagesChat.scss';

const Message = ({
  createdDate, message, fromDonor, userMedia, userFullName, officer, receipt, last,
}) => {
  const src = !fromDonor ? officer?.profile?.picture : userMedia;
  const profilePicture = src ?
    (
      <img
        src={src}
        alt="User profile avatar."
        className="profile-image"
      />
    ) :
    null;

  const now = new Date();
  const isToday = now.valueOf() - createdDate.valueOf() > 86400000;
  const isThisYear = now.getFullYear() === createdDate.getFullYear();
  const dateTimeMessage = isToday ?
    `on ${format(createdDate, `MMM do${isThisYear ? '' : ', yyyy'}`)}` :
    `at ${format(createdDate, 'h:mm a')}`;

  const name = fromDonor ?
    userFullName :
    `${officer?.profile?.firstName} ${officer?.profile?.lastName}`;

  const receiptMessage =
    isToday ?
      `Read on ${format(
        createdDate,
        `MMM do${isThisYear ? '' : ', yyyy'}`
      )}` :
      `Read at ${format(createdDate, 'h:mm a')}`;

  return (
    <div
      className={classNames('message-wrapper', {
        mine: !fromDonor,
        last,
      })}
    >
      <div className="profile-picture-wrapper">{profilePicture}</div>
      <div className="message-info-wrapper">
        <div className="message-info-row">
          <span className="user-name">{name}</span>
          {` ${dateTimeMessage}`}
        </div>
        <div className="message-contents">{message}</div>
        {!fromDonor && (
          <div className="read-indicator">{receipt ? receiptMessage : 'Delivered'}</div>
        )}
      </div>
    </div>
  );
};

const MessagesChat = ({
  messages, sendMessage, markMessagesAsRead, userFullName, userMedia,
}) => {
  const [
    emojiPickerOpen,
    setEmojiPickerOpen,
  ] = useState(false);
  const emojiPickerRef = useRef();
  const messagesContainerRef = useRef();
  const [
    message,
    setMessage,
  ] = useState('');

  useEffect(() => {
    markMessagesAsRead();
  }, []);

  const updateMessage = ({ target }) => setMessage(target.value);

  const openEmojiPicker = () => setEmojiPickerOpen(true);

  const addEmoji = emoji => {
    setMessage(message + emoji?.native);
    setEmojiPickerOpen(false);
  };

  const scrollMessagesToBottom = () => {
    const { current = {} } = messagesContainerRef || {};

    current.scrollTop = current?.scrollHeight;
  };

  useEffect(() => {
    scrollMessagesToBottom();
  }, [messages]);

  useOnClickOutside(emojiPickerRef, () => setEmojiPickerOpen(false));

  const emojiPicker = emojiPickerOpen ?
    (
      <div
        ref={emojiPickerRef}
        className="emoji-picker-wrapper"
        onClick={e => e.stopPropagation()}
      >
        <Picker
          onSelect={addEmoji}
          title="Pick your emoji"
          emoji="point_up"
        />
      </div>
    ) :
    null;

  const messageItems = messages?.map(messageItem => (
    <Message
      createdDate={messageItem.createdDate}
      message={messageItem.message}
      fromDonor={messageItem.fromDonor}
      officer={messageItem.officer}
      receipt={messageItem.receipt}
      last={messageItem.last}
      userFullName={userFullName}
      userMedia={userMedia}
      key={messageItem.messageId}
    />
  )) || [];

  const onSendMessage = async () => {
    const trimmedMessage = message.trim();

    if (trimmedMessage) {
      setMessage('');
      await sendMessage(trimmedMessage);
    }
  };

  useOnEnterPressed(onSendMessage);

  return (
    <div className="messages">
      <div
        className="messages-container"
        ref={messagesContainerRef}
      >
        {messageItems.length ?
          (
            messageItems
          ) :
          (
            <div className="zero-state-wrapper">
              <div className="zero-state-message">
                  No message history - say something to get the conversation going!
              </div>
            </div>
          )}
      </div>
      <div className="message-input-bar">
        <button
          type="button"
          className={classNames('emoji icon-wrapper', { open: emojiPickerOpen })}
          onClick={openEmojiPicker}
        >
          <FaRegSmile
            size={22}
            color="var(--Gray600)"
          />
          {emojiPicker}
        </button>
        <input
          className="message-input"
          placeholder="Type something..."
          value={message}
          onChange={updateMessage}
        />
        <button
          type="button"
          className="send icon-wrapper"
          onClick={onSendMessage}
        >
          <MdSend
            size={24}
            color="var(--White)"
          />
        </button>
      </div>
    </div>
  );
};

export default MessagesChat;
